<script>
import CertificateGeneratorIconFeedback from './CertificateGeneratorIconFeedback.vue';
import CertificateGeneratorButton from './CertificateGeneratorButton.vue';

export default {
    name: 'CertificateGeneratorForm',
    components: {
        CertificateGeneratorIconFeedback,
        CertificateGeneratorButton
    },
    props: {
        successfulGenerationMessage: { type: String, required: true },
        waitingGenerationMessage: { type: String, required: true },
        vinRegistrationExistsApi: { type: String, required: true },
        vinNumberText: { type: String, required: true },
        vinNumberHelpText: { type: String, required: true },
        generateCertificateButtonText: { type: String, required: true },
        errorGenerationMessage: { type: String, required: true },
    },
    emits: ['vin-number-validated'],
    data() {
        return {
            vinNumber: '',
            loading: false,
            error: false,
            successfullyAdded: false,
        };
    },

    computed: {
        isResultsShown() {
            return this.loading || this.error || this.successfullyAdded;
        },
    },

    methods: {
        async validateVinNumber() {
            if (this.vinNumber.length < 8) return;
            this.resetPreValidatedState();
            await this.validate();
        },

        resetPreValidatedState() {
            this.loading = true;
            this.error = false;
            this.successfullyAdded = false;
        },

        async validate() {
            try {
                const response = await this.validationRequest();
                if (this.isNotFound(response)) {
                    this.setError();
                    return;
                }
                if (this.isValid(response)) {
                    this.updateVinNumbers(response);
                }
            } catch (error) {
                this.error = true;
                console.error(error);
            } finally {
                this.loading = false;
            }
        },

        async validationRequest() {
            const response = await fetch(`${this.vinRegistrationExistsApi}?vinRegistration=${this.vinNumber}`);
            if (!response.ok) {
                throw new Error('Something went wrong while validating the chassis number', response.status);
            }
            return await response.json();
        },

        isNotFound(response) {
            return response.result !== 'Found';
        },

        setError() {
            this.loading = false;
            this.error = true;
        },

        isValid(response) {
            return !!response.londonDirectVisionStarRating;
        },

        updateVinNumbers(response) {
            this.$emit('vin-number-validated', response.londonDirectVisionStarRating);
            this.successfullyAdded = true;
        },

        resetSuccessStatus() {
            this.error = false;
            this.successfullyAdded = false;
        }
    },
};
</script>

<template>
    <form class="input-wrapper pt-3" @submit.prevent="validateVinNumber">
        <label for="vinNumber">{{ vinNumberText }}</label>
        <div class="search-block flex-column flex-sm-row">
            <div class="search-block__input form-group position-relative">
                <input
                    id="vinNumber"
                    v-model="vinNumber"
                    type="text"
                    :placeholder="vinNumberText"
                    class="form-control"
                    :class="{'is-invalid': error, 'is-valid': successfullyAdded}"
                    @input="resetSuccessStatus"
                />
                <CertificateGeneratorIconFeedback v-if="successfullyAdded" :is-valid="true" />
                <CertificateGeneratorIconFeedback v-if="error" :is-valid="false" />
            </div>
            <CertificateGeneratorButton
                :text="generateCertificateButtonText"
                :disabled="vinNumber.length < 8 || loading"
                :loading="loading"
                :no-margin-bottom="true"
            />
        </div>
        <div class="help-text-block">
            <p>{{ vinNumberHelpText }}</p>
        </div>
        <div
            v-if="isResultsShown"
            class="status-field"
            :class="{'is-invalid': error, 'is-valid': successfullyAdded}"
        >
            <p v-if="loading">{{ waitingGenerationMessage }}</p>
            <div v-else-if="error">
                <p>{{ errorGenerationMessage }}</p>
            </div>
            <p v-else-if="successfullyAdded">{{ successfulGenerationMessage }}</p>
        </div>
    </form>
</template>
