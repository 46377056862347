<script>
import SitecoreFields from '../models/SitecoreFields.js';
import CertificateGeneratorTabSelector from './CertificateGeneratorTabSelector.vue';
import CertificateGeneratorForm from './CertificateGeneratorForm.vue';
import CertificateGeneratorDownloads from './CertificateGeneratorDownloads.vue';
import CertificateGeneratorFormBulk from './CertificateGeneratorFormBulk.vue';
import CertificateGeneratorDownloadsBulk from './CertificateGeneratorDownloadsBulk.vue';

export default {
    name: 'CertificateGenerator',
    components: {
        CertificateGeneratorTabSelector,
        CertificateGeneratorForm,
        CertificateGeneratorDownloads,
        CertificateGeneratorFormBulk,
        CertificateGeneratorDownloadsBulk
    },
    props: {
        sitecoreFields: { type: SitecoreFields, required: true }
    },
    data() {
        return {
            loading: false,
            vinNumbersList: [],
            isBulkMode: false,
            bulkVinNumbers: '',
            showSingleDownloads: false,
            showBulkDownloads: false,
        };
    },
    methods: {
        updateVinNumbersList(vin) {
            this.vinNumbersList.push(vin);
            this.showSingleDownloads = true;
        },
        updateBulkVinNumbers(vins) {
            this.bulkVinNumbers = vins;
            this.showBulkDownloads = true;
        },
    },
};
</script>

<template>
    <div class="certificate-generator">
        <CertificateGeneratorTabSelector
            :single-tab-text="sitecoreFields.singleCertificateTabText"
            :bulk-tab-text="sitecoreFields.bulkCertificateTabText"
            @select-mode="(value) => isBulkMode = value"
        />
        <div v-show="!isBulkMode">
            <CertificateGeneratorForm
                :successful-generation-message="sitecoreFields.successfulGenerationMessage"
                :waiting-generation-message="sitecoreFields.waitingGenerationMessage"
                :vin-registration-exists-api="sitecoreFields.vinRegistrationExistsAPI"
                :vin-number-text="sitecoreFields.vinNumberText"
                :vin-number-help-text="sitecoreFields.vinNumberHelpText"
                :generate-certificate-button-text="sitecoreFields.generateCertificateButtonText"
                :error-generation-message="sitecoreFields.errorGenerationMessage"
                @vin-number-validated="updateVinNumbersList"
            />
            <CertificateGeneratorDownloads
                v-if="showSingleDownloads"
                :title="sitecoreFields.downloadsSectionTitle"
                :vin-number-text="sitecoreFields.vinNumberText"
                :license-plate-text="sitecoreFields.licensePlateText"
                :download-file-link-text="sitecoreFields.downloadFileLinkText"
                :download-all-files-link-text="sitecoreFields.downloadAllFilesLinkText"
                :generate-pdf-api="sitecoreFields.generatePDFAPI"
                :vin-numbers-list="vinNumbersList"
                :loading="loading"
            />
        </div>
        <div v-show="isBulkMode">
            <CertificateGeneratorFormBulk
                :successful-generation-message="sitecoreFields.successfulGenerationMessage"
                :waiting-generation-message="sitecoreFields.waitingGenerationMessage"
                :validate-bulk-upload-api="sitecoreFields.validateBulkUploadAPI"
                :bulk-generate-certificates-button-text="sitecoreFields.bulkGenerateCertificatesButtonText"
                :bulk-vin-number-text="sitecoreFields.bulkVINNumberText"
                :bulk-vin-numbers-help-text="sitecoreFields.bulkVINNumbersHelpText"
                :bulk-error-generation-message="sitecoreFields.bulkErrorGenerationMessage"
                @bulk-vin-numbers-validated="updateBulkVinNumbers"
            />
            <CertificateGeneratorDownloadsBulk
                v-if="showBulkDownloads"
                :title="sitecoreFields.downloadsSectionTitle"
                :download-file-link-text="sitecoreFields.bulkDownloadFileLinkText"
                :generate-bulk-pdf-api="sitecoreFields.generateBulkPDFAPI"
                :bulk-vin-numbers="bulkVinNumbers"
                :loading="loading"
                @download-started="loading = true"
                @download-ended="() => { loading = false; bulkVinNumbers = ''; }"
            />
        </div>
    </div>
</template>
