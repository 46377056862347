import OneTrustCookieHandler from '@/Project/Common/code/src/scripts/lib/OneTrustCookieHandler';

const isSubset = (superObj, subObj) => {
    return Object.keys(subObj).every((ele) => {
        return subObj[ele] === superObj[ele];
    });
};

const CookieStore = {
    state: {
        cookieValues: null,
    },
    mutations: {
        setCookieValues(state, obj) {
            let convertedObj = OneTrustCookieHandler.mapToOneTrustCookie(obj);
            let currentObj = state.cookieValues;

            if (!currentObj) {
                currentObj = OneTrustCookieHandler.getDefaultState();
            }

            if (!isSubset(currentObj, convertedObj)) {
                const newObj = { ...currentObj, ...convertedObj };
                state.cookieValues = newObj;

                OneTrustCookieHandler.updateAcceptedCookies(convertedObj);
            }
        },
        initializeCookieState(state) {
            if (!state.cookieValues) {
                let defaultState = OneTrustCookieHandler.getDefaultState();
                let currentActiveCookies = OneTrustCookieHandler.getActiveGroups()
                    .split(',')
                    .filter((n) => n);

                Object.keys(defaultState).forEach(function (key) {
                    defaultState[key] = currentActiveCookies.includes(key);
                });

                state.cookieValues = defaultState;
            }
        },
    },
    actions: {},
    getters: {
        getCookieValues: (state) => state.cookieValues,
    },
};

export default CookieStore;
