<template>
  <img
    :src="props.src"
    :srcset="`
        ${props.src}mw=576 576w,
        ${props.src}?mw=768 768w,
        ${props.src}?mw=992 992w,
        ${props.src}?mw=1920 1920w`"
    sizes="(min-width: 577px) 768px, (min-width: 769px) 992px, (min-width: 993px) 1920px, 576px"
    class="img-fluid"
    :class="props.cssClass"
    :alt="props.alt">
</template>

<script setup>
const props = defineProps({
    src: { type: String, default: null },
    cssClass: { type: String, default: null },
    alt: { type: String, default: null }
});
</script>