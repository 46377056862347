<template>
    <div
        class="catwalk"
        :class="{
            'is-building-up': isBuildingUp,
            'build-up-finished': hasBuildUpFinished,
        }"
        data-dm="component.catwalk"
        @mousemove="throttledMouseMove"
    >
        <div class="catwalk__build-up-reveal-layer"></div>
        <div ref="imageWrapper" class="catwalk__image-wrapper">
            <img
                :src="objectsImage.mw992"
                :srcset="`${objectsImage.mw1920} 1920w, ${objectsImage.mw992} 992w,
                  ${objectsImage.mw768} 768w, ${objectsImage.mw576} 576w`"
                sizes="(min-width: 993px) 1200px, (min-width: 769px) 992px, (min-width: 577px) 768px, 576px"
                loading="lazy"
                :alt="objectsImage.alt"
                class="catwalk__image-layer catwalk__image-layer--objects"
                :style="objectsTransformTranslateXStyle"
            />
            <img
                :src="tarmacImage.mw992"
                :srcset="`${tarmacImage.mw1920} 1920w, ${tarmacImage.mw992} 992w,
                  ${tarmacImage.mw768} 768w, ${tarmacImage.mw576} 576w`"
                sizes="(min-width: 993px) 1200px, (min-width: 769px) 992px, (min-width: 577px) 768px, 576px"
                loading="lazy"
                :alt="tarmacImage.alt"
                class="catwalk__image-layer catwalk__image-layer--tarmac"
                :style="tarmacTranslateXStyle"
            />
            <img
                :src="skyImage.mw992"
                :srcset="`${skyImage.mw1920} 1920w, ${skyImage.mw992} 992w,
                  ${skyImage.mw768} 768w, ${skyImage.mw576} 576w`"
                sizes="(min-width: 993px) 1200px, (min-width: 769px) 992px, (min-width: 577px) 768px, 576px"
                loading="lazy"
                :alt="skyImage.alt"
                class="catwalk__image-layer catwalk__image-layer--sky"
                :style="skyTranslateXStyle"
            />
        </div>
        <div ref="swiperContainer" class="swiper swiper-container">
            <div class="catwalk-details__divider d-lg-none" :style="{ height: `${mobileDetailsHeight}px` }"></div>
            <div class="swiper-wrapper">
                <div
                    v-for="(_, index) in focusPoints"
                    :key="index"
                    class="swiper-slide"
                    :class="{ 'is-active-desktop': (!isMobile && isBuildingUp) || isActiveDesktopDetail(index) }"
                    :style="getDesktopDetailsTranslateXStyle(index)"
                    @mouseenter="onDetailsHover(index)"
                    @mouseleave="onDetailsLeave"
                    @focusin="onDetailsHover(index)"
                    @focusout="onDetailsLeave"
                >
                    <div ref="details" class="catwalk-details">
                        <slot :name="`detail-${index + 1}`" />
                    </div>
                </div>
            </div>
        </div>
        <div
            class="catwalk__gradient catwalk__gradient--left d-lg-none"
            :class="{ 'is-active': !hasFirstFocusPointActive }"
        ></div>
        <div
            class="catwalk__gradient catwalk__gradient--right d-lg-none"
            :class="{ 'is-active': !hasLastFocusPointActive }"
        ></div>
        <div
            class="catwalk__gradient catwalk__gradient--bottom is-active d-lg-none"
            :style="{ height: `${mobileDetailsHeight}px` }"
        ></div>
        <div class="catwalk__progress d-lg-none">
            <div
                v-for="(point, index) in focusPoints"
                :key="point.left"
                class="catwalk__progress-dot"
                :class="{ 'is-active': isActiveFocusPoint(index) }"
            ></div>
        </div>
    </div>
</template>

<script>
import lodash from 'lodash';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

const CATWALK_DESKTOP_BREAKPOINT = 992;

export default {
    name: 'Catwalk',
    props: {
        objectsImage: { type: Object, default: () => ({}) },
        skyImage: { type: Object, default: () => ({}) },
        tarmacImage: { type: Object, default: () => ({}) },
        focusPoints: { type: Array, default: () => [] },
    },
    data() {
        return {
            swiper: null,
            isMobile: true,
            isBuildingUp: false,
            hasBuildUpFinished: false,
            viewportWidth: null,
            imageWidth: null,
            mobileDetailsHeight: 0,
            skyTranslateX: 0,
            desktopActiveDetailIndex: 1,
            desktopDetailOffsets: [],
            activeFocusPointIndex: 0,
        };
    },
    computed: {
        objectsTransformTranslateXStyle() {
            const transformLength = this.getActiveFocusPointImageTranslateX();
            return { transform: `translateX(${transformLength}px)` };
        },
        skyTranslateXStyle() {
            if (!this.isMobile) {
                // move the sky on desktop
                return { transform: `translateX(${this.skyTranslateX.toFixed(2)}px)` };
            }

            const initialTransformLength = this.getActiveFocusPointImageTranslateX();
            const transformLengthOffset = (initialTransformLength / 5) * this.activeFocusPointIndex + 1;
            const transformLength = initialTransformLength - transformLengthOffset;

            return { transform: `translateX(${transformLength}px)` };
        },
        tarmacTranslateXStyle() {
            const initialTransformLength = this.getActiveFocusPointImageTranslateX();
            const transformLengthOffset = (initialTransformLength / 10) * this.activeFocusPointIndex + 1;
            const transformLength = initialTransformLength - transformLengthOffset;

            return { transform: `translateX(${transformLength}px)` };
        },
        hasFirstFocusPointActive() {
            if (!this.swiper) return true;
            return this.swiper.isBeginning;
        },
        hasLastFocusPointActive() {
            if (!this.swiper) return false;
            return this.swiper.isEnd;
        },
    },
    created() {
        this.throttledMouseMove = lodash.throttle(this.onDesktopMouseMove, 100);
    },
    mounted() {
        this.viewportWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        this.imageWidth = this.$refs.imageWrapper.offsetWidth;
        this.isMobile = this.viewportWidth <= CATWALK_DESKTOP_BREAKPOINT;
        this.mobileDetailsHeight = this.$refs.details[0].offsetHeight;

        if (this.isMobile) {
            this.swiper = new Swiper(this.$refs.swiperContainer, {
                modules: [Navigation],
                speed: 1250,
                followFinger: false,
                on: {
                    slideChange: (swiper) => {
                        this.activeFocusPointIndex = swiper.activeIndex;
                    }
                }
            });
        }

        this.startBuildUpWhenCatwalkIsInView();

        window.addEventListener('scroll', lodash.throttle(this.startBuildUpWhenCatwalkIsInView, 150));
    },
    methods: {
        startBuildUpWhenCatwalkIsInView() {
            // build-up has already happened
            if (this.isBuildingUp || this.hasBuildUpFinished) {
                return;
            }

            if (this.isCatwalkAlmostInView()) {
                this.startBuildUp().then(() => {
                    if (!this.isMobile) {
                        this.desktopDetailOffsets = this.getDesktopDetailOffsets();
                    }
                });
            }
        },
        isCatwalkAlmostInView() {
            const bottomOffset = 300;
            const rect = this.$el.getBoundingClientRect();
            const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
            const isAlmostInView = viewportHeight - (rect.bottom - bottomOffset) > 0;
            return isAlmostInView;
        },
        startBuildUp() {
            if (this.isMobile) {
                return this.startMobileBuildUp();
            } else {
                return this.startDesktopBuildUp();
            }
        },
        startMobileBuildUp() {
            const buildUpTransitionDuration = 3000;
            const showUpTransitionDuration = 3000;

            this.isBuildingUp = true;

            return new Promise((resolve) => {
                this.$nextTick(() => {
                    this.goToFocusPoint(this.focusPoints.length - 1);
                });

                setTimeout(() => {
                    const middleFocusPointIndex = Math.floor(this.focusPoints.length / 2);
                    this.goToFocusPoint(middleFocusPointIndex, 0);

                    setTimeout(() => {
                        this.isBuildingUp = false;
                        this.hasBuildUpFinished = true;
                        resolve();
                    }, showUpTransitionDuration);
                }, buildUpTransitionDuration);
            });
        },
        startDesktopBuildUp() {
            const buildUpTransitionDuration = 1500;
            const showUpTransitionDuration = 3000;

            this.isBuildingUp = true;

            return new Promise((resolve) => {
                setTimeout(() => {
                    this.hasBuildUpFinished = true;
                    setTimeout(() => {
                        this.isBuildingUp = false;
                        resolve();
                    }, showUpTransitionDuration);
                }, buildUpTransitionDuration);
            });
        },
        onDesktopMouseMove(e) {
            if (this.isMobile) {
                return;
            }
            this.setSkyTranslateX(e);
        },
        /**
     * Make the sky layer move left and right depending on the mouse move
     */
        setSkyTranslateX(catwalkMouseMoveEvent) {
            const catwalkClientRect = catwalkMouseMoveEvent.target.getBoundingClientRect();
            const xOffset = catwalkMouseMoveEvent.offsetX || catwalkMouseMoveEvent.layerX;
            const xOffsetPercentage = (xOffset + catwalkClientRect.left) / this.imageWidth;
            const SKY_TRANSFORM_MAX_X = 40;
            const SKY_TRANSFORM_MIN_X = -SKY_TRANSFORM_MAX_X;
            this.skyTranslateX = xOffsetPercentage * (SKY_TRANSFORM_MAX_X - SKY_TRANSFORM_MIN_X) + SKY_TRANSFORM_MIN_X;
        },
        getActiveFocusPointImageTranslateX() {
            const activeFocusPoint = this.focusPoints[this.activeFocusPointIndex].left;
            return this.getBaseImageLayerTranslateX(activeFocusPoint);
        },
        /**
     * Calculates the translateX in pixels to center a focus point on mobile
     */
        getBaseImageLayerTranslateX(focusPoint) {
            if (!this.imageWidth) {
                return 0;
            }

            const halfOfViewportWidth = this.viewportWidth / 2;
            const imageOffset = -(this.imageWidth * (focusPoint / 100));

            let translateX = imageOffset + halfOfViewportWidth;

            const crossesLeftImageBoundary = translateX > 0;
            const crossesRightImageBoundary = (Math.abs(translateX) + this.viewportWidth) > this.imageWidth;

            // make sure the image always stays within the viewport
            // else move the image as far to the edge as possible
            if (crossesLeftImageBoundary) {
                translateX = 0;
            } else if (crossesRightImageBoundary) {
                translateX = -Math.abs(this.imageWidth - this.viewportWidth);
            }

            return translateX;
        },
        /**
     * These offsets are used on desktop to initially cover the remaining USP items
     */
        getDesktopDetailsTranslateXStyle(detailIndex) {
            if (this.isActiveDesktopDetail(detailIndex)) {
                // no translateY needed, shows the full details
                return null;
            }
            return { transform: `translateY(${this.desktopDetailOffsets[detailIndex]}px)` };
        },
        getDesktopDetailOffsets() {
            return this.$refs.details.map((catwalkDetail) => {
                const uspItems = catwalkDetail.querySelectorAll('[data-usp-item]');
                const detailLink = catwalkDetail.querySelector('a');

                if (uspItems.length <= 1) {
                    if (detailLink) {
                        return catwalkDetail.offsetHeight - detailLink.offsetTop;
                    }

                    // use padding to align the catwalk detail titles
                    const catwalkDetailPaddingBottom = parseFloat(window.getComputedStyle(catwalkDetail).paddingBottom);
                    return catwalkDetailPaddingBottom;
                }

                // every usp item below will be hidden
                const firstHiddenItem = uspItems[1]; // second item

                return catwalkDetail.offsetHeight - firstHiddenItem.offsetTop;
            });
        },
        goToFocusPoint(focusPointIndex) {
            this.swiper.slideTo(focusPointIndex, 0);
        },
        isActiveDesktopDetail(detailIndex) {
            if (this.isMobile) return false;
            return this.desktopActiveDetailIndex === detailIndex;
        },
        isActiveFocusPoint(focusPointIndex) {
            return this.activeFocusPointIndex === focusPointIndex;
        },
        onDetailsHover(detailIndex) {
            this.desktopActiveDetailIndex = detailIndex;
        },
        onDetailsLeave() {
            this.desktopActiveDetailIndex = -1;
        }
    },
};
</script>
