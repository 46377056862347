<template>
    <div>
        <div
            class="header-video has-dark-overlay-desktop"
            :class="videoSizeClass"
            :style="{ backgroundImage: `url(${backgroundImage})` }"
        >
            <BaseVimeoPlayer
                v-if="isDesktop || (!isDesktop && video.isLoaded)"
                ref="videoPlayer"
                :url="videoUrl"
                :options="video.options"
                @loaded="onVideoLoaded"
                @timeupdate="onVideoUpdate"
                @error="onVideoError"
                @play="onVideoPlay"
                @pause="onVideoPause"
                @volumechange="onVideoVolumeChange"
            />
            <button
                class="header-video__play-toggle-overlay-mobile"
                :title="video.isPlaying ? dictionary.videoPause : dictionary.videoPlay"
                @click="toggleVideoPlayMobile"
            >
                <span v-if="!isDesktop && !video.isPlaying" class="icon">
                    <svg role="img">
                        <use :href="videoPlayToggleIconHref"></use>
                    </svg>
                </span>
            </button>
            <div v-if="video.isLoaded" class="header-video__controls">
                <button
                    class="header-video__control-button"
                    :title="video.isPlaying ? dictionary.videoPause : dictionary.videoPlay"
                    @click="toggleVideoPlay"
                >
                    <!-- :key attribute used below to update svg when state changes (IE11) -->
                    <span :key="video.isPlaying ? 'playing' : 'paused'" class="icon">
                        <svg role="img">
                            <use :href="videoPlayToggleIconHref"></use>
                        </svg>
                    </span>
                </button>
                <button
                    class="header-video__control-button"
                    :title="video.isMuted ? dictionary.videoUnmuteVolume : dictionary.videoMuteVolume"
                    @click="toggleVideoVolume"
                >
                    <!-- :key attribute used below to update svg when state changes (IE11) -->
                    <span :key="video.isMuted ? 'muted' : 'unmuted'" class="icon">
                        <svg role="img">
                            <use :href="videoVolumeIconHref"></use>
                        </svg>
                    </span>
                </button>
            </div>
        </div>
        <div
            class="header-video__content hero-header__content container"
            :class="`header-video__content--${alignContent}`"
        >
            <div class="header-video__body">
                <slot name="content" />
            </div>
        </div>
    </div>
</template>

<script>
import BaseVimeoPlayer from '@/Feature/Media/code/src/components/BaseVimeoPlayer.vue';

export default {
    name: 'HeaderVideo',
    components: { BaseVimeoPlayer },
    props: {
        videoUrl: {
            type: String,
            required: true,
        },
        backgroundImage: {
            type: String,
            required: true,
        },
        size: {
            type: String,
            validator: (v) => ['small', 'medium', 'large'].includes(v),
            default: 'medium',
        },
        alignContent: {
            type: String,
            validator: (v) => ['left', 'right'].includes(v),
            default: 'left',
        },
        dictionary: {
            type: Object,
            default: () => ({}),
        }
    },
    data() {
        return {
            video: {
                options: {
                    background: true, // remove controls, autoplay, loop and mute
                    dnt: true, // privacy friendly
                },
                isLoaded: false,
                isPlaying: false,
                isMuted: true,
            },
            isDesktop: false,
        };
    },
    computed: {
        videoSizeClass() {
            return this.size !== 'medium' ? `header-video--${this.size}` : null;
        },
        videoPlayToggleIconHref() {
            const icon = this.video.isPlaying ? 'pause-circle' : 'play-circle';
            return `/assets/icons/common/ui.svg#${icon}`;
        },
        videoVolumeIconHref() {
            const icon = this.video.isMuted ? 'fa-volume-off' : 'fa-volume-up';
            return `/assets/icons/common/ui.svg#${icon}`;
        },
    },
    mounted() {
    // viewport needs additional time to initialize toolkit
        setTimeout(() => {
            this.isDesktop = viewport.is('>=lg');
        }, 250);
    },
    methods: {
        toggleVideoPlay() {
            if (this.video.isPlaying) {
                this.$refs.videoPlayer.pause();
            } else {
                this.$refs.videoPlayer.play();
            }
        },
        toggleVideoPlayMobile() {
            if (!this.$refs.videoPlayer) {
                this.video.isLoaded = true;
            } else {
                this.toggleVideoPlay();
            }
        },
        toggleVideoVolume() {
            if (this.video.isMuted) {
                this.$refs.videoPlayer.setVolume();
            } else {
                this.$refs.videoPlayer.mute();
            }
        },
        onVideoLoaded() {
            this.video.isLoaded = true;
        },
        onVideoUpdate(data) {
            if (this.video.isPlaying) {
                return;
            }
            // some browsers might not emit play event by default
            if (data.seconds > 0) {
                this.onVideoPlay();
            }
        },
        onVideoError(error) {
            console.error(error);
        },
        onVideoPlay() {
            this.video.isPlaying = true;
        },
        onVideoPause() {
            this.video.isPlaying = false;
        },
        onVideoVolumeChange({ volume }) {
            this.video.isMuted = (volume === 0);
        },
    }
};
</script>