class SitecoreFields {
    vinRegistrationExistsAPI = {
        type: String,
        required: true,
    };
    generatePDFAPI = {
        type: String,
        required: true,
    };
    validateBulkUploadAPI = {
        type: String,
        required: true,
    };
    vinNumberText = {
        type: String,
        required: true,
    };
    generateCertificateButtonText = {
        type: String,
        required: true,
    };
    vinNumberHelpText = {
        type: String,
        required: true,
    };
    successfulGenerationMessage = {
        type: String,
        required: true,
    };
    errorGenerationMessage = {
        type: String,
        required: true,
    };
    waitingGenerationMessage = {
        type: String,
        required: true,
    };
    downloadsSectionTitle = {
        type: String,
        required: true,
    };
    licensePlateText = {
        type: String,
        required: true,
    };
    downloadAllFilesLinkText = {
        type: String,
        required: true,
    };
    downloadFileLinkText = {
        type: String,
        required: true,
    };
    singleCertificateTabText = {
        type: String,
        required: true,
    };
    bulkCertificateTabText = {
        type: String,
        required: true,
    };
    bulkGenerateCertificatesButtonText = {
        type: String,
        required: true,
    };
    bulkVINNumberText = {
        type: String,
        required: true,
    };
    bulkVINNumbersHelpText = {
        type: String,
        required: true,
    };
    bulkErrorGenerationMessage = {
        type: String,
        required: true,
    };
    generateBulkPDFAPI = {
        type: String,
        required: true,
    };
    bulkDownloadFileLinkText = {
        type: String,
        required: true,
    };
}

export default SitecoreFields;
