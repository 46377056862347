<template>
    <div class="scrollytelling" data-dm="component.scrollytelling" :class="{ 'has-scroll': isSubcontentUnfolded }">
        <div ref="container" class="scrollytelling__container" :class="{ 'is-active': !isSubcontentUnfolded }">
            <nav
                ref="nav"
                data-dm="component.on-page navigation"
                :class="{ 'is-visible': isNavigationVisible, 'initial-transition': !isNavigationPeekDone }"
                class="scrollytelling-menu"
                aria-labelledby="menu"
                :aria-expanded="isNavigationVisible.toString()"
                @mouseover="navigationMouseOver"
                @mouseout="navigationMouseOut"
                @focus="navigationMouseOver"
                @focusout="navigationMouseOut"
            >
                <ul class="scrollytelling-menu__list">
                    <li class="scrollytelling-menu__item scrollytelling-menu__close">
                        <button
                            data-dm-event-action="menu-toggle"
                            :data-dm-element-value="isNavigationVisible ? 'open' : 'close'"
                            @click="showNavigation(false)"
                        >
                            <span class="scrollytelling__label">
                                {{ dictionary.navigationClose }}
                            </span>
                            <div class="scrollytelling__icon-wrapper ml-3">
                                <span class="icon">
                                    <svg role="img">
                                        <use href="/assets/icons/common/ui.svg#close"></use>
                                    </svg>
                                </span>
                            </div>
                        </button>
                    </li>
                </ul>

                <ul class="scrollytelling-menu__list">
                    <li>
                        <span class="scrollytelling__label scrollytelling__menu-indicator">
                            {{ dictionary.navigationMenuLabel }}
                        </span>
                    </li>
                    <li
                        v-for="(slide, index) in slideshow"
                        :key="slide.title"
                        :class="{ 'is-active': isActiveSlide(slide) }"
                        class="scrollytelling-menu__item scrollytelling-menu__item--nav"
                    >
                        <button
                            data-dm-event-action="link-internal"
                            :data-dm-element-value="slide.title"
                            @click="goToSlide(index)"
                            @keydown="goToSlide(index)"
                        >
                            {{ slide.title }}
                        </button>
                    </li>
                </ul>

                <ul class="scrollytelling-menu__list">
                    <li v-if="downloadLink" class="scrollytelling-menu__item">
                        <a :href="downloadLink" download class="scrollytelling__external-link">
                            <span class="icon mr-2">
                                <svg role="img">
                                    <use href="/assets/icons/common/ui.svg#chevron-right"></use>
                                </svg>
                            </span>
                            {{ dictionary.downloadLinkText }}
                        </a>
                    </li>
                </ul>
            </nav>

            <div ref="swiperContainer" class="swiper swiper-container" :style="transformUpStyle">
                <div class="swiper-wrapper">
                    <div
                        v-for="(slide, index) in slideshow"
                        :key="slide.title"
                        ref="slide"
                        :data-hash="getHashNameFromSlide(slide)"
                        class="swiper-slide scrollytelling-slide"
                        :class="{ 'scrollytelling-slide--intro': slide.type === 'intro' }"
                    >
                        <div>
                            <img
                                :key="index"
                                :style="transformDownStyle"
                                class="scrollytelling-slide__image"
                                :src="slide.img.mw992"
                                :srcset="createSrcSetFromSlide(slide)"
                                :alt="slide.alt"
                                loading="lazy"
                            />
                            <div class="scrollytelling-slide__gradient"></div>
                        </div>
                        <div class="scrollytelling-slide__container">
                            <div class="scrollytelling-slide__body will-animate">
                                <slot v-if="slide.type === 'intro'" name="introductionslide" />
                                <slot v-else :name="'slidecontent' + slide.slideId"></slot>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="scrollytelling__subcontent" :class="{ 'is-active': isSubcontentUnfolded }">
                <div
                    v-for="(slide, index) in slides"
                    v-show="isActiveSlide(slide)"
                    :key="slide.slideId"
                    :style="marginTopStyle"
                    class="scrollytelling__subcontainer"
                >
                    <slot :name="'slidesubcontent' + slide.slideId"></slot>
                    <img
                        v-if="index + 1 < slides.length"
                        :key="index"
                        :style="calculatedHeight"
                        class="scrollytelling__image-preview"
                        :src="getNextSlide(index).img.mw992"
                        :srcset="createSrcSetFromSlide(getNextSlide(index))"
                        :alt="slide.alt"
                    />
                </div>
            </div>

            <div class="scrollytelling__progress" :class="{ 'is-hidden': isSubcontentUnfolded || isNavigationVisible }">
                <button
                    class="scrollytelling__label scrollytelling__menu-indicator"
                    data-dm-event-action="menu-toggle"
                    :data-dm-element-value="isNavigationVisible ? 'open' : 'close'"
                    @click="showNavigation(true)"
                    @keydown="showNavigation(true)"
                >
                    {{ dictionary.navigationMenuLabel }}
                </button>
                <div
                    v-for="(slide, index) in slideshow"
                    :key="index"
                    :class="{ 'is-active': isActiveSlide(slide) }"
                    class="scrollytelling__progress-indicator"
                    @click="goToSlide(index)"
                    @keydown="goToSlide(index)"
                ></div>
            </div>

            <div :class="{ 'is-hidden': activeIndex > 0 || isTransitioning }" class="scrollytelling-scroll-indicator">
                <div class="scrollytelling-scroll-indicator__title">
                    {{ dictionary.scrollIndicator }}
                </div>
                <div class="scrollytelling__icon-wrapper scrollytelling__icon-wrapper--large">
                    <span class="icon">
                        <svg role="img">
                            <use href="/assets/icons/common/ui.svg#arrow-forward"></use>
                        </svg>
                    </span>
                </div>
            </div>

            <scrollytelling-dragger
                ref="dragger"
                :value="draggerValue"
                :collapsed="isSubcontentUnfolded && hasScrolledDownSubcontent"
                :label-open="dictionary.draggerOpen"
                :label-close="dictionary.draggerClose"
                :class="{ 'is-hidden': !activeSlide.showDetails || isTransitioning || areSlidesOutOfView }"
                @click="showNavigation(false)"
                @update="onDraggerUpdate"
                @state="onDraggerStateChange"
            >
            </scrollytelling-dragger>
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper';
import { Keyboard, Mousewheel, HashNavigation, A11y } from 'swiper/modules';

import lodash from 'lodash';
import ScrollytellingDragger from './ScrollytellingDragger.vue';

export default {
    components: {
        ScrollytellingDragger,
    },
    props: {
        title: { type: String, required: true },
        subtitle: { type: String, required: true },
        intro: { type: String, required: true },
        anchor: { type: String, required: true },
        backgroundImg: { type: Object, required: true },
        backgroundImgAlt: { type: String, default: undefined },
        slides: { type: Array, required: true },
        downloadLink: { type: String, default: null },
        dictionary: { type: Object, default: () => ({}) },
        pageInfo: { type: Object, default: () => ({}) },
    },
    data() {
        return {
            swiper: null,
            activeIndex: 0,
            isNavigationVisible: false,
            hasScrolledDownSubcontent: false,
            areSlidesOutOfView: false,
            isNavigationPeekDone: false,
            draggerValue: 0,
            navigationOffset: '0px',
        };
    },
    computed: {
        isSubcontentUnfolded() {
            return this.draggerValue > 0;
        },
        slideshow() {
            return [
                {
                    type: 'intro',
                    showDetails: false,
                    title: this.title,
                    topic: this.subtitle,
                    text: this.intro,
                    anchor: this.anchor,
                    img: this.backgroundImg,
                    alt: this.backgroundImgAlt
                },
                ...this.slides
            ];
        },
        activeSlide() {
            return this.activeIndex !== -1 ? this.slideshow[this.activeIndex] : {};
        },
        isTransitioning() {
            return this.swiper ? this.swiper.animating : false;
        },
        isAtEnd() {
            return this.swiper ? this.swiper.isEnd : false;
        },
        marginTopStyle() {
            return { marginTop: `-${this.draggerValue * 0.6}px` };
        },
        transformUpStyle() {
            return { transform: `translateY(-${this.draggerValue * 0.6}px)` };
        },
        transformDownStyle() {
            return { transform: `translateY(${this.draggerValue * 0.6}px)` };
        },
        calculatedHeight() {
            return { height: `calc(100vh - ${this.navigationOffset})` };
        },
    },
    watch: {
        isSubcontentUnfolded(newValue) {
            if (newValue) {
                this.swiper.mousewheel.disable();
                this.swiper.keyboard.disable();
                this.swiper.allowTouchMove = false;
                this.showNavigation(false);
                this.disablePageOverflow(false);

                this.$nextTick(() => {
                    this.triggerResize();
                    this.resizeSubcontentComponents();
                });
            } else {
                this.swiper.mousewheel.enable();
                this.swiper.keyboard.enable();
                this.swiper.allowTouchMove = true;

                if (!this.isAtEnd) {
                    this.disablePageOverflow(true);
                }
            }
        },
        areSlidesOutOfView(newValue) {
            if (newValue) {
                this.swiper.mousewheel.disable();
                this.swiper.keyboard.disable();
                this.swiper.allowTouchMove = false;
            } else {
                this.swiper.mousewheel.enable();
                this.swiper.keyboard.enable();
                this.swiper.allowTouchMove = true;
            }
        },
        draggerValue(newValue, oldValue) {
            const scrollTopOffset = window.scrollY;
            // if dragger is swiped from right to left
            if (newValue < oldValue) {
                if (scrollTopOffset > 0) {
                    const decrement = scrollTopOffset / newValue;
                    const newOffset = scrollTopOffset - decrement;
                    window.scrollTo({ top: newOffset });
                }
                // if dragger is swiped from left to right
            } else {
                if (scrollTopOffset <= 150) {
                    window.scrollTo({ top: 1.5 * newValue });
                } else {
                    const increment = scrollTopOffset / newValue;
                    const newOffset = scrollTopOffset + increment;
                    window.scrollTo({ top: newOffset });
                }
            }
        },
        isNavigationVisible(newValue) {
            this.disablePageOverflow(newValue);
        },
    },
    mounted() {
        // obtain top offset from the body to calculate height
        this.navigationOffset = getComputedStyle(document.body).paddingTop;
        this.$el.style.height = this.calculatedHeight.height;
        this.$refs.nav.style.top = this.navigationOffset;

        this.swiper = new Swiper(this.$refs.swiperContainer, {
            modules: [Keyboard, Mousewheel, HashNavigation, A11y],
            direction: 'vertical',
            speed: 1000,
            on: {
                slideChange: (swiper) => {
                    this.showNavigation(false);
                    this.activeIndex = swiper.activeIndex;
                },
                transitionEnd: () => {
                    this.animateSlideBody();
                    this.prepareNextSlideForAnimation();
                },
                click: () => {
                    this.showNavigation(false);
                },
            },
            resistance: false,
            mousewheel: true,
            keyboard: {
                enabled: true,
                onlyInViewport: false,
            },
            hashNavigation: true,
            a11y: true,
        });

        this.disablePageOverflow(true);

        this.animateSlideBody();

        const willHashLinkToSlide = window.location.hash;
        if (willHashLinkToSlide) {
            if (this.activeSlide.showDetails) {
                this.$refs.dragger.open();
            }
        }

        setTimeout(() => {
            // always start at the top
            window.scrollTo({ top: 0 });

            if (!willHashLinkToSlide) {
                // don't open the navigation if there is a direct link to a slide
                this.peekNavigation();
            }
        }, 1000);

        window.addEventListener('scroll', lodash.debounce(this.onScroll, 100));
    },
    methods: {
        goToSlide(index, speed) {
            this.swiper.slideTo(index, speed);
        },
        goToNextSlide(speed, callback) {
            this.swiper.slideNext(speed, callback);
        },
        getNextSlide(index) {
            if (index + 1 === this.slides.length) {
                return {};
            }
            return this.slides[index + 1];
        },
        /**
     * Use hero header to fade in gradient and text
     */
        animateSlideBody() {
            const slideRef = this.$refs.slide[this.activeIndex];
            if (slideRef) {
                slideRef.querySelector('.scrollytelling-slide__body').classList.add('reveal');
                slideRef.querySelector('.scrollytelling-slide__gradient').classList.add('fade-in');
            }
        },
        /**
     * Use the active slide index to remove animation classes
     */
        prepareNextSlideForAnimation() {
            const hasNextSlide = this.activeIndex + 1 < this.$refs.slide.length;
            const nextSlideRef = hasNextSlide ? this.$refs.slide[this.activeIndex + 1] : null;

            if (nextSlideRef) {
                const gradient = nextSlideRef.querySelector('.scrollytelling-slide__gradient');
                const reveal = nextSlideRef.querySelector('.scrollytelling-slide__body');
                gradient.classList.remove('fade-in');
                reveal.classList.remove('reveal');
            }
        },
        createSrcSetFromSlide(slide) {
            return `${slide.img.mw1920} 1920w, ${slide.img.mw992} 992w, ${slide.img.mw768} 768w, ${slide.img.mw576} 576w`;
        },
        disablePageOverflow(shouldDisable) {
            const disableScrollClass = 'disable-desktop-scroll';
            if (shouldDisable) {
                document.documentElement.classList.add(disableScrollClass);
            } else {
                document.documentElement.classList.remove(disableScrollClass);
            }
        },
        isActiveSlide(slide) {
            return this.activeSlide.title === slide.title;
        },
        showNavigation(visible) {
            this.isNavigationVisible = visible;
        },
        peekNavigation() {
            if (this.isNavigationVisible) {
                return;
            }

            this.showNavigation(true);
            setTimeout(() => {
                if (!this.isHoveringNavigation) {
                    this.showNavigation(false);
                }
                this.isNavigationPeekDone = true;
            }, 3000);
        },
        navigationMouseOver() {
            this.isHoveringNavigation = true;
        },
        navigationMouseOut() {
            this.isHoveringNavigation = false;
        },
        onScroll() {
            const scrollTopOffset = this.getScrollTopOffset();
            this.areSlidesOutOfView = !this.isSubcontentUnfolded && scrollTopOffset > 0;
            this.hasScrolledDownSubcontent = this.isSubcontentUnfolded && scrollTopOffset > 200; // 200px margin from top

            if (this.isSubcontentUnfolded && this.hasReachedEndOfSubcontainer()) {
                this.$refs.dragger.close();

                if (this.isAtEnd) {
                    // scroll to footer after small interval
                    setTimeout(() => {
                        const footerOffset = document.querySelector('footer').getBoundingClientRect().top;
                        const footerOffsetTopMinusNavigation = footerOffset - parseInt(this.navigationOffset, 10);
                        window.scrollTo({ top: footerOffsetTopMinusNavigation, behavior: 'smooth' });
                    }, 250);
                } else {
                    this.goToNextSlide(0);
                    window.scrollTo({ top: 0 });
                }
            }
        },
        getScrollTopOffset() {
            const { scrollTop, clientTop } = document.documentElement;
            const topOffset = (window.pageYOffset || scrollTop) - (clientTop || 0);
            return topOffset;
        },
        hasReachedEndOfSubcontainer() {
            return Math.ceil(window.innerHeight + window.pageYOffset) >= document.body.scrollHeight;
        },
        /**
     * Create an URL friendly id from a slide
     * @param {object} slide to get id from
     * @returns {string} id
     */
        getHashNameFromSlide(slide) {
            return slide.anchor && encodeURIComponent(slide.anchor.replace(/\s+/g, '-').toLowerCase());
        },
        /**
     * Triggered before unfolding subcontainer to
     * re-initialize events not emitted due to display none
     */
        triggerResize() {
            if (typeof (Event) === 'function') {
                // modern browsers
                window.dispatchEvent(new Event('resize'));
            } else {
                // for IE and other old browsers
                // causes deprecation warning on modern browsers
                const event = window.document.createEvent('UIEvents');
                event.initUIEvent('resize', true, false, window, 0);
                window.dispatchEvent(event);
            }
        },
        resizeSubcontentComponents() {
            Array.from(this.$el.querySelectorAll('.slick-initialized'))
                .forEach(container => container.slick.setPosition());
        },
        onDraggerUpdate(value) {
            this.draggerValue = value;
        },
        onDraggerStateChange(state) {
            // mock button click
            const mockTarget = document.createElement('button');
            mockTarget.dataset.dm = `component.${this.activeSlide.title} slide`;
            mockTarget.dataset.dmEventAction = 'dragger';
            mockTarget.dataset.dmElementValue = state;

            window.digitalData.handleEvent({ type: 'click', currentTarget: mockTarget });
        }
    }
};
</script>
