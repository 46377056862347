<template>
    <header ref="headroomheader" class="header sticky-top headroom bg-dark">
        <slot />
    </header>
</template>

<script>
import Headroom from 'headroom.js';
import lodash from 'lodash';

export default {
    mounted() {
        const pageHeader = this.$refs.headroomheader;

        // sticky header
        if (!scEditMode) {
            if (pageHeader) {
                let headerHeight = pageHeader.clientHeight;
                if (!$('.app-vocationals')[0]) {
                    new Headroom(pageHeader, { offset: headerHeight, tolerance: {down:10, up:0} }).init();
                    if((window.location.href.indexOf('truck-configurator') < 0) && 1 >= headerHeight || 400 <= headerHeight){
                        switch (1 >= headerHeight || 400 <= headerHeight) {
                            case (!(768 < window.innerWidth)):
                                headerHeight = 65;
                                break;
                            case (!(992 < window.innerWidth)):
                                headerHeight = 103;
                                break;
                            case (!(1200 < window.innerWidth)):
                                headerHeight = 150;
                                break;
                            default:
                                headerHeight = 150;
                        }
                    }
                    $(document.body).css('padding-top', headerHeight);
                } else {
                    pageHeader.classList.remove('headroom', 'sticky-top');
                }
            }
        } else {
            console.warn('Edit mode, Preview or Debug modus: Prevented Navigation Scroll functionality.');
        }

        window.addEventListener('resize', lodash.debounce(() => {
            // update body padding-top to header height
            if (pageHeader) {
                let headerHeight = pageHeader.clientHeight;
                if (!$('.app-vocationals')[0]) {
                    $(document.body).css('padding-top', headerHeight);
                }
            }

            // check height of header and position the mobile menu
            this.checkHeaderHeight();
        }));
    },

    methods: {
        // check height of header and position the mobile menu
        checkHeaderHeight() {
            const pageHeader = this.$refs.headroomheader;
            let headerHeight = pageHeader.clientHeight;
            const $mobileNav = $('.mobile-navigation');
            if (pageHeader && $mobileNav) {
                $mobileNav.css('top', headerHeight);
            }
        }
    }

};
</script>