<script>
import Player from '@vimeo/player';

const PlayerEvents = [
    'play',
    'playing',
    'pause',
    'ended',
    'timeupdate',
    'progress',
    'volumechange',
    'loaded',
];

export default {
    name: 'BaseVimeoPlayer',
    props: {
        videoId: { type: String, default: undefined },
        url: { type: String, default: undefined },
        height: { type: String, default: 'None' },
        width: { type: String, default: 'None' },
        options: { type: Object, default: () => ({})}
    },
    watch: {
        videoId(newId) {
            this.loadVideo(newId);
        }
    },
    mounted() {
        this.initPlayer();
    },
    beforeDestroy() {
        this.player.unload();
    },
    methods: {
        initPlayer() {
            this.instantiatePlayer();
            this.waitUntilPlayerIsReady();
            this.emitPlayerEvents();
        },

        instantiatePlayer() {
            this.player = new Player(
                this.$refs.player,
                Object.assign(this.defaultOptions(), this.options)
            );
        },

        defaultOptions() {
            const result = {
                id: this.videoId,
                width: this.width,
                height: this.height,
            };
            // URL overrides ID, so only use the URL if set
            if (this.url) {
                result.url = this.url;
            }
            return result;
        },

        waitUntilPlayerIsReady() {
            this.player
                .ready()
                .then(() => this.$emit('ready', this.player))
                .catch((error) => this.$emit('error', error, this.player));
        },

        emitPlayerEvents() {
            PlayerEvents.forEach((event) => {
                this.player.on(event, (data) => this.$emit(event, data, this.player));
            });
        },

        loadVideo(id) {
            this.player.loadVideo(id);
        },

        play() {
            this.player.play();
        },

        pause() {
            this.player.pause();
        },

        mute() {
            this.player.setVolume(0);
        },

        setVolume(volume = 0.5) {
            this.player.setVolume(volume);
        }
    }
};
</script>

<template>
    <div ref="player" class="vimeo-player" />
</template>