<template>
    <div
        class="doorway"
        :class="{
            'has-2-doors': doors.length === 2,
            'has-active-door': hasActiveDoor,
        }"
        data-dm="component.doorway"
    >
        <ul class="doorway__doors list-unstyled">
            <li
                v-for="(door, index) in doors"
                :key="index"
                class="doorway__door"
                :class="{ 'is-active': isActiveDoor(index) }"
                @mouseenter="onDoorHover(index)"
                @mouseleave="onDoorLeave(index)"
                @focusin="onDoorHover(index)"
                @focusout="onDoorLeave(index)"
            >
                <img
                    ref="doorImages"
                    :src="door.image.mw1920"
                    :srcset="`${door.image.mw1920} 1920w, ${door.image.mw992} 992w,
                    ${door.image.mw768} 768w, ${door.image.mw576} 576w`"
                    sizes="(min-width: 993px) 1200px, (min-width: 769px) 992px, (min-width: 577px) 768px, 576px"
                    :alt="door.image.alt"
                    class="doorway__door-image object-fit-cover"
                    loading="lazy"
                />
                <div class="doorway__door-overlay"></div>
                <div class="doorway__door-overlay-tint"></div>
                <div class="doorway__door-content">
                    <div class="doorway__door-hide-overflow">
                        <div ref="doorDetails" class="doorway__door-detail" :style="getDoorDetailTransformStyle(index)">
                            <slot :name="`detail-${index}`" />
                        </div>
                    </div>
                    <slot :name="`link-${index}`" :link="door.link" />
                </div>
                <a :href="door.link" class="doorway__door-link-overlay-desktop" aria-hidden="true"> </a>
            </li>
        </ul>
    </div>
</template>

<script>
import objectFitImages from 'object-fit-images';

export default {
    name: 'Doorway',
    props: {
        doors: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            activeDoorIndex: -1,
            doorDetailElementHeights: [],
            isDesktop: true,
        };
    },
    computed: {
        hasActiveDoor() {
            return this.activeDoorIndex !== -1;
        },
    },
    mounted() {
    // wait until refs have been initialized
        this.$nextTick(() => {
            this.doorDetailElementHeights = this.getDoorDetailElementsHeights();
            objectFitImages(this.$refs.doorImages);
        });

        // viewport needs additional time to initialize toolkit
        setTimeout(() => {
            this.isDesktop = viewport.is('>=lg');
        }, 250);
    },
    methods: {
    /**
     * Obtains a door's transform styling for mobile/desktop
     * Initially hides part of the detail content
     * On door hover this content will become visible
     */
        getDoorDetailTransformStyle(doorIndex) {
            if (this.doorDetailElementHeights.length === 0) {
                // door details have not yet been mounted
                return null;
            }

            if (!this.hasActiveDoor) {
                return this.getNeutralDoorDetailTransformStyle(doorIndex);
            }

            if (this.isActiveDoor(doorIndex)) {
                // let css handle transform
                return null;
            }

            // door with another index is active
            return this.getInactiveDoorDetailTransformStyle(doorIndex);
        },
        getNeutralDoorDetailTransformStyle(doorIndex) {
            const { summary } = this.doorDetailElementHeights[doorIndex];
            return { transform: `translateY(${summary}px)` };
        },
        getInactiveDoorDetailTransformStyle(doorIndex) {
            const { subtitle, summary } = this.doorDetailElementHeights[doorIndex];
            const offsetHeight = this.isDesktop ? summary : (subtitle + summary);
            return { transform: `translateY(${offsetHeight}px)` };
        },
        getDoorDetailElementsHeights() {
            return this.$refs.doorDetails.map(doorDetail => {
                const subtitle = doorDetail.querySelector('[data-subtitle]');
                const summary = doorDetail.querySelector('[data-summary]');
                return {
                    subtitle: this.getAbsoluteElementHeight(subtitle),
                    summary: this.getAbsoluteElementHeight(summary)
                };
            });
        },
        getAbsoluteElementHeight(el) {
            const styles = window.getComputedStyle(el);
            const margin = parseFloat(styles.marginTop) + parseFloat(styles.marginBottom);
            return Math.ceil(el.offsetHeight + margin);
        },
        isActiveDoor(doorIndex) {
            return this.activeDoorIndex === doorIndex;
        },
        onDoorHover(doorIndex) {
            this.activeDoorIndex = doorIndex;
        },
        onDoorLeave() {
            this.activeDoorIndex = -1;
        },
    }
};
</script>
