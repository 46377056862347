<template>
    <transition mode="out-in" name="fade">
        <BaseGdprTemplate
            v-if="!requiredCookieAccepted && type === 'youtube'"
            :id="videoId"
            :gdpr-title="gdprTitle"
            :gdpr-text="gdprText"
            :image="image"
            css-class="container embed-responsive embed-responsive-16by9"
            :checkbox-clicked="acceptCookie"
        />

        <div v-else class="video-player" data-dm="component.videoplayer-vimeo">
            <div v-show="!showVideo" class="video-player__poster">
                <BaseImage v-if="image" :src="image" css-class="bg js-object-fit object-fit-cover" alt="" />
                <div class="gradient" />
                <button class="video-player__playbutton" data-dm="videoplayer-vimeo.button-play" @click="onVideoPlay">
                    <svg class="icon icon--play-circle" role="img">
                        <use href="/assets/icons/common/ui.svg#play-circle" />
                    </svg>
                </button>
                <div class="container">
                    <h3 class="video-player__title">
                        {{ title }}
                    </h3>
                </div>
            </div>

            <GdprYoutubePlayer
                v-if="requiredCookieAccepted && type === 'youtube'"
                ref="videoplayer"
                :video-id="videoId"
                :player-id="playerId"
                :type="type"
                @stopVideo="stopVideo"
            />
            <VimeoPlayer
                v-if="type === 'vimeo'"
                ref="videoplayer"
                :video-url="videoUrl"
                :type="type"
                @stopVideo="stopVideo"
            />
        </div>
    </transition>
</template>

<script>
import BaseGdprTemplate from '@/Feature/CookieConsent/code/src/components/BaseGDPRTemplate.vue';
import BaseGdprLogic from '@/Feature/CookieConsent/code/src/components/BaseGDPRLogic.vue';
import GdprYoutubePlayer from '@/Feature/Media/code/src/components/GdprYoutubePlayer.vue';
import VimeoPlayer from '@/Feature/Media/code/src/components/VimeoPlayer.vue';
import BaseImage from '@/Foundation/Components/BaseImage.vue';

export default {
    components: {
        BaseGdprTemplate,
        GdprYoutubePlayer,
        VimeoPlayer,
        BaseImage
    },
    extends: BaseGdprLogic,
    props: {
        type: { type: String, default: null },
        image: { type: String, default: null },
        title: { type: String, default: null },
        videoId: { type: String, default: null },
        videoUrl: { type: String, default: null },
        playerId: { type: String, default: null },
        gdprTitle: { type: String, default: null },
        gdprText: { type: String, default: null },
        requiredCookie: { type: String, default: null },
    },
    data(){
        return{
            showVideo: false,
        };
    },
    methods: {
        onVideoPlay(){
            this.showVideo = true;
            this.$refs.videoplayer.onVideoPlay();
        },
        stopVideo(bool){
            this.showVideo = bool;
        }
    }
};
</script>
