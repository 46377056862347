<template>
  <div>
    <input
      :id="props.id"
      type="checkbox"
      :value="props.value"
      :name="props.name"
      :checked="props.checked"
      :disabled="props.disabled"
      :autocomplete="props.autocomplete"
      @click="props.checkboxClicked()">
    <label :for="props.id">
      {{ props.label }}
    </label>
  </div>
</template>

<script setup>
const props = defineProps({
    id: { type: String, default: null },
    value: { type: String, default: null },
    name: { type: String, default: null },
    label: { type: String, default: null },
    checked: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    autocomplete: { type: Boolean, default: false },
    checkboxClicked: { type: Function, default: null }
});
</script>
