<script>
import CertificateGeneratorIconFeedback from './CertificateGeneratorIconFeedback.vue';
import CertificateGeneratorButton from './CertificateGeneratorButton.vue';

export default {
    name: 'CertificateGeneratorFormBulk',
    components: {
        CertificateGeneratorIconFeedback,
        CertificateGeneratorButton
    },
    props: {
        successfulGenerationMessage: { type: String, required: true },
        waitingGenerationMessage: { type: String, required: true },
        validateBulkUploadApi: { type: String, required: true },
        bulkGenerateCertificatesButtonText: { type: String, required: true },
        bulkVinNumberText: { type: String, required: true },
        bulkVinNumbersHelpText: { type: String, required: true },
        bulkErrorGenerationMessage: { type: String, required: true },
    },
    emits: ['bulk-vin-numbers-validated'],
    data() {
        return {
            loading: false,
            error: false,
            successfullyAdded: false,
            bulkVinNumbers: '',
            failedBulkVinRegistrations: [],
        };
    },

    computed: {
        isResultsShown() {
            return this.loading || this.error || this.successfullyAdded;
        },
    },

    methods: {
        async validateBulkVinNumbers() {
            if (this.bulkVinNumbers.length < 8) return;
            this.resetPreValidatedState();
            await this.validate();
        },

        resetPreValidatedState() {
            this.loading = true;
            this.error = false;
            this.successfullyAdded = false;
            this.failedBulkVinRegistrations = [];
        },

        async validate() {
            try {
                const response = await this.validationRequest();
                if (this.hasInvalidVinNumbers(response)) {
                    this.handleInvalidVinNumbers(response);
                }
                if (response.inputValid) {
                    this.updateVinNumbers();
                }
            } catch (error) {
                this.error = true;
                console.error(error);
            } finally {
                this.loading = false;
            }
        },

        async validationRequest() {
            const response = await fetch(this.validateBulkUploadApi, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({bulkVinRegistrations: this.bulkVinNumbers})
            });
            if (!response.ok) {
                throw new Error('Something went wrong while validating the chassis number(s)', response.status);
            }
            return await response.json();
        },

        hasInvalidVinNumbers(response) {
            return response.failedVinRegistrations.length > 0;
        },

        handleInvalidVinNumbers(response) {
            this.failedBulkVinRegistrations = response.failedVinRegistrations;
            this.error = true;
        },

        updateVinNumbers() {
            this.error = false;
            this.successfullyAdded = true;
            this.$emit('bulk-vin-numbers-validated', this.bulkVinNumbers);
        },

        resetSuccessStatus() {
            this.error = false;
            this.successfullyAdded = false;
        }
    },
};
</script>

<template>
    <form class="input-wrapper pt-3" @submit.prevent="validateBulkVinNumbers">
        <label for="bulkVinNumber">{{ bulkVinNumberText }}</label>
        <div class="search-block flex-column">
            <div class="search-block__input form-group position-relative">
                <textarea
                    id="bulkVinNumber"
                    v-model="bulkVinNumbers"
                    name="bulkVinNumber"
                    class="form-control"
                    :class="{'is-invalid': error, 'is-valid': successfullyAdded}"
                    rows="6"
                    @input="resetSuccessStatus"
                >
                </textarea>
                <CertificateGeneratorIconFeedback v-if="successfullyAdded" :is-valid="true" />
                <CertificateGeneratorIconFeedback v-if="error" :is-valid="false" />
            </div>
            <CertificateGeneratorButton
                :text="bulkGenerateCertificatesButtonText"
                :disabled="bulkVinNumbers.length < 8 || loading"
                :loading="loading"
            />
        </div>
        <div class="help-text-block">
            <p>{{ bulkVinNumbersHelpText }}</p>
        </div>
        <div
            v-if="isResultsShown"
            class="status-field"
            :class="{'is-invalid': error, 'is-valid': successfullyAdded}"
        >
            <p v-if="loading">{{ waitingGenerationMessage }}</p>
            <div v-else-if="error">
                <p>{{ bulkErrorGenerationMessage }}</p>
                <ul v-if="failedBulkVinRegistrations.length > 0">
                    <li v-for="(failedVin, i) in failedBulkVinRegistrations" :key="`${failedVin}-${i}`">
                        {{ failedVin }}
                    </li>
                </ul>
            </div>
            <p v-else-if="successfullyAdded">{{ successfulGenerationMessage }}</p>
        </div>
    </form>
</template>
