<script>
import createPdfDownloadLinkFromBlob from '../util/downloadLink.js';
import CertificateGeneratorIconDownload from './CertificateGeneratorIconDownload.vue';

export default {
    name: 'CertificateGeneratorDownloadsBulk',
    components: { CertificateGeneratorIconDownload },
    props: {
        title: { type: String, required: true },
        downloadFileLinkText: { type: String, required: true },
        generateBulkPdfApi: { type: String, required: true },
        bulkVinNumbers: { type: String, required: true },
        loading: { type: Boolean, default: false },
    },
    emits: ['download-started', 'download-ended'],
    methods: {
        getLastEightChars(str) {
            return str.slice(-8);
        },

        async downloadBulkPdf() {
            this.$emit('download-started');
            this.error = false;

            try {
                const response = await fetch(this.generateBulkPdfApi, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({bulkVinRegistrations: this.bulkVinNumbers})
                });

                if (!response.ok) {
                    throw new Error('Something went wrong genarating the PDF', response.status);
                }

                const blob = await response.blob();
                createPdfDownloadLinkFromBlob(blob, 'certificates-bulk');
            } catch (error) {
                this.error = true;
                console.error(error);
            } finally {
                this.$emit('download-ended');
            }
        },
    }
};
</script>

<template>
    <div class="downloads-section mb-6">
            <div class="certificates">
                <div class="certificate">
                    <div class="downloads-section__title">
                        <span>{{ title }}</span>
                    </div>
                    <div class="certificate__download">
                        <button
                            :ref="'downloadBulkPdfButton'"
                            class="btn btn-outline-primary btn-sm cert-btn"
                            :class="{'cert-btn--loading': loading}"
                            type="button"
                            :disabled="loading"
                            @click="downloadBulkPdf"
                         >
                            <CertificateGeneratorIconDownload />
                            <span class="button__text">{{ downloadFileLinkText }}</span>
                        </button>
                    </div>
                    <hr />
                </div>
            </div>
        </div>
</template>