<template>
    <div class="scrollytelling-dragger" :class="{ 'is-open': isOpen, 'is-collapsed': collapsed }">
        <div ref="slider" />
        <svg
            ref="chevrons"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        >
            <polyline points="13 17 18 12 13 7" />
            <polyline points="6 17 11 12 6 7" />
        </svg>
    </div>
</template>

<script>
import noUiSlider from 'nouislider';

const DRAGGER_BOUNDARY = 50;
const DRAGGER_OPEN = 100;
const DRAGGER_CLOSED = 0;

export default {
    props: {
        value: { type: Number, default: 0 },
        collapsed: { type: Boolean, default: false },
        labelOpen: { type: String, default: undefined },
        labelClose: { type: String, default: undefined },
    },
    computed: {
        isOpen() {
            return this.value > DRAGGER_BOUNDARY;
        },
    },
    watch: {
        isOpen(newValue) {
            const label = this.$el.querySelector('.scrollytelling-dragger__label');
            if (!label) return;
            label.textContent = newValue ? this.labelClose : this.labelOpen;
        },
    },
    mounted() {
        const { slider, chevrons } = this.$refs;

        const instance = noUiSlider.create(slider, {
            start: 0,
            range: { min: 0, max: 100 },
            behaviour: 'drag',
        });

        instance.on('update', this.onUpdate);
        instance.on('change', this.onChange);

        this.$nextTick(() => {
        // add custom icons to handle
            const handle = this.$el.querySelector('.noUi-handle');

            const touchArea = document.createElement('div');
            touchArea.className = 'noUi-touch-area';
            touchArea.appendChild(chevrons);

            handle.appendChild(touchArea);

            // add labels
            const labelNode = document.createElement('span');
            labelNode.className = 'scrollytelling-dragger__label';
            labelNode.textContent = this.labelOpen;
            this.$el.querySelector('.noUi-connects').appendChild(labelNode);
        });
    },
    methods: {
    /**
     * handle boundary
     */
        onChange(values, handles) {
            const value = parseInt(values[handles], 10);

            if (value > DRAGGER_BOUNDARY) {
                this.open();
                this.$emit('state', 'open');
            } else {
                this.close();
                this.$emit('state', 'closed');
            }
        },
        /**
     * method to open dragger as ref in parent component
     */
        open() {
            this.setValue(DRAGGER_OPEN);
        },
        /**
     * method to close dragger as ref in parent component
     */
        close() {
            this.setValue(DRAGGER_CLOSED);
        },
        /**
     * will trigger update and change event
     */
        setValue(newValue) {
            this.$refs.slider.noUiSlider.set(newValue);
        },
        onUpdate(values, handles) {
            const value = parseInt(values[handles], 10);
            this.$emit('update', value);
        },
    }
};
</script>
