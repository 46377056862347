/**
 * Wrapper functionality to do jquery ajax requests.
 *
 * @memberOf module:project/Common
 * @version 1.0.0
 * @author Rocco Janse <rocco.janse@valtech.nl>
 */
class AjaxHandler {

    /**
     * Create a AjaxHandler instance.
     * @param {object} options Options object.
     * @param {string} [options.type='GET'] Type of request (GET, POST, PUT, DELETE).
     * @param {string} options.url Url where API is located on.
     * @param {object} options.data Data to send to request url.
     * @param {string} [options.dataType='json'] Data type.
     * @param {string} [options.contentType='application/json; charset=utf-8'] Content type.
     */
    constructor(options) {

        this.xhr = null;

        // default config
        this.config = $.extend({
            type: 'POST',
            url: '',
            data: {},
            dataType: 'json',
            contentType: 'application/json; charset=utf-8'
        }, options);
    }

    /**
     * Returns data object from request.
     * @param {object} options Options object.
     * @param {string} [options.type='GET'] Type of request (GET, POST, PUT, DELETE).
     * @param {string} options.url Url where API is located on.
     * @param {object} options.data Data to send to request url.
     * @param {string} [options.dataType='json'] Data type.
     * @param {string} [options.contentType='application/json; charset=utf-8'] Content type.
     * @returns {promise}
     */
    request(options) {
        return new Promise((resolve, reject) => {

            // get config
            let config = $.extend({}, this.config);
            config = $.extend(config, options);

            // cancel previous request, if there is one
            //this.cancelRequest();
            //console.log(this.xhr);

            // Send antiforgery header & token with every request
            let antiForgeryToken = $('meta[name=anti-forgery-token]').attr('content');
            let antiForgeryHeader = {};
            antiForgeryHeader['__RequestVerificationToken'] = antiForgeryToken;

            this.xhr = $.ajax({
                type: config.type,
                url: config.url,
                data: config.data,
                dataType: config.dataType,
                contentType: config.contentType,
                headers: antiForgeryHeader
            }).done((response) => {

                //this.xhr = null;
                resolve(response);

                // if (typeof cb === 'function') {
                //     cb(response);
                // }

            }).fail((d, textStatus, error) => {

                // this.xhr = null;

                // // when failed, but not aborted
                if (textStatus !== 'abort') {
                    console.error(`getJSON failed, status: ${textStatus}, error: ${error}`);
                    reject(error);
                } else {
                    resolve({});
                }
            });
        });
    }

    /**
     * Cancels request to server.
     */
    cancelRequest() {
        if (this.xhr !== null) {
            this.xhr.abort();
            this.xhr = null;
        }
        return;
    }
}

export default AjaxHandler;