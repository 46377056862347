<template>
    <div class="container video-player__video embed-responsive embed-responsive-16by9" :data-type="type">
        <BaseVimeoPlayer
            v-if="hasVideoStarted"
            ref="videoPlayer"
            :url="videoUrl"
            :options="video.options"
            @error="onVideoError"
            @ended="onVideoEnd"
        />
    </div>
</template>

<script>
import BaseVimeoPlayer from '@/Feature/Media/code/src/components/BaseVimeoPlayer.vue';

export default {
    components: {
        BaseVimeoPlayer,
    },
    props: {
        type: { type: String, default: null },
        videoUrl: {type: String,default: undefined},

    },
    data() {
        return {
            hasVideoStarted: false,
            video: {
                options: {
                    dnt: true, // privacy friendly
                    autoplay: true,
                },
            },
        };
    },
    methods: {
        onVideoEnd() {
            this.$emit('stopVideo', false);
            this.hasVideoStarted = false;
        },
        onVideoError(error) {
            console.error(error);
        },
        onVideoPlay() {
            this.hasVideoStarted = true;
        },
    },
};
</script>