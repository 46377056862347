<template>
    <div class="base-gdpr-template">
        <div
            v-if="image"
            class="base-gdpr-template__background js-responsive-background"
            :style="[image && `background-image: url(${image});`]"
        >
            <base-image v-if="image" :src="image" css-class="bg d-none" alt="" />
            <div class="gradient" />
        </div>
        <div :class="cssClass">
            <div class="base-gdpr-template__inner-container col-md-8">
                <h2 class="base-gdpr-template__title text-center">
                    {{ gdprTitle }}
                </h2>
                <p class="base-gdpr-template__text text-center">
                    {{ gdprText }}
                </p>
                <base-checkbox
                    :id="`gdpr-checkbox-${id}`"
                    :name="`gdpr-checkbox-${id}`"
                    :value="`gdpr-checkbox-${id}`"
                    autocomplete="off"
                    :checkbox-clicked="checkboxClicked"
                />
            </div>
        </div>
    </div>
</template>

<script>
import BaseImage from '@/Foundation/Components/BaseImage.vue';
import BaseCheckbox from '@/Foundation/Components/BaseCheckbox.vue';

export default {
    components: { BaseImage, BaseCheckbox },
    props: {
        id: { type: String, default: null },
        image: { type: String, default: null },
        cssClass: { type: String, default: null },
        gdprTitle: { type: String, default: null },
        gdprText: { type: String, default: null },
        checkboxClicked: { type: Function, default: null },
    }
};
</script>