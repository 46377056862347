function createPdfDownloadLinkFromBlob(blob, filename) {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.pdf`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
}

export default createPdfDownloadLinkFromBlob;
