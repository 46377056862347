/**
 * @typedef {string} HubspotProperty
 **/

/**
 * Enum for HubSpot property types
 * @readonly
 * @enum {HubspotProperty}
 */
export const HubspotPropertyType = {
    CONTACT: 'contact',
    COMPANY: 'company',
};

/**
 * Generic HubSpot property helpers which can be used to simulate form fields
 */
const PropertyHelpers = {
    [HubspotPropertyType.CONTACT]: {
        /**
         * @param {string} name hubspot property field name
         * @param {string} formId hubspot form ID
         */
        generateFormFieldId: (name, formId) => `${name}-${formId}`,
        generateFormFieldName: (name) => name,
    },
    [HubspotPropertyType.COMPANY]: {
        /**
         * The name of company property form fields start with '0-2'
         * @param {string} name hubspot property field name
         * @param {string} formId hubspot form ID
         */
        generateFormFieldId: (name, formId) => `0-2/${name}-${formId}`,
        generateFormFieldName: (name) => `0-2/${name}`,
    }
};

export const HubspotFormsHelper = {
    /**
     * Creates and returns a resemblance of a default form field in HubSpot
     * This way you can simulate HubSpot fields by filling them with dynamic data
     * @param {object} data property field config
     * @param {string} data.inputType form field input type
     * @param {string} data.name hubspot property field name
     * @param {string} data.value hubspot property field value
     * @param {string} data.label hubspot property field label
     * @param {HubspotProperty} data.propertyType hubspot property type
     * @param {string} data.formId hubspot form ID
     * @param {object} data.containerElementAttrs element attributes of form field container
     * @returns {jQuery} form field container
     */
    createCustomPropertyField({ inputType = 'hidden', name, value, label, propertyType, formId, containerElementAttrs }) {
        const propertyHelper = PropertyHelpers[propertyType];

        if (!propertyHelper) {
            throw new Error(`Hubspot property type ${propertyType} does not exist`);
        }

        const propertyId = propertyHelper.generateFormFieldId(name, formId);
        const propertyName = propertyHelper.generateFormFieldName(name);

        const $input = $('<input>').attr({
            type: inputType,
            id: propertyId,
            name: propertyName,
            value,
        });
        const $label = $(`<label><span>${label}</span></label>`).attr({ id: `label-${propertyId}`, 'for': propertyId });

        return $('<div>').attr(containerElementAttrs)
            .append($label)
            .append(
                $('<div>').append($input)
            );
    },
    /**
     * Can update the validation state of an existing HubSpot form field
     * @param {jQuery} $field hubspot form field
     * @param {boolean} isValid validation state
     * @param {string} message hubspot validation message
     */
    validateField($field, isValid, message) {
        if (isValid) {
            $field.removeClass('invalid error');
            $field.parent().siblings('.hs-error-msgs').remove();
        } else {
            if (!$field.hasClass('invalid')) {
                $field.addClass('invalid error');
                if (message) {
                    HubspotFormsHelper.createErrorLabel(message).insertAfter($field.parent());
                }
            }
        }
    },
    /**
     * Recreate the HubSpot error message
     * @param {string} message
     */
    createErrorLabel(message) {
        const $list = $('<ul>')
            .addClass('no-list hs-error-msgs inputs-list')
            .css('display', 'block')
            .attr('role', 'alert');
        const $listItem = $('<li>');
        const $label = $('<label>', { 'class': 'hs-error-msg', text: message });
        return $list.append($listItem.append($label));
    },
};