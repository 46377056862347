import { createApp } from 'vue';

// Vuex store(s)
import { store } from './store.common.js';

// Components
import Catwalk from '@/Feature/Hotspot/code/src/components/Catwalk.vue';
import MobileLanguageSelector from '@/Feature/Language/code/Components/MobileLanguageSelector.vue';
import DaffcVocationals from '@/Feature/Vocationals/code/src/components/daffc-vocationals.vue';
import Countdown from '@/Feature/Countdown/code/src/components/Countdown.vue';
import TruckFilterOverview from '@/Feature/TruckList/code/src/components/TruckFilterOverview.vue';
import Doorway from '@/Feature/Teasers/code/src/components/Doorway.vue';
import ContentCarousel from '@/Feature/Teasers/code/src/components/ContentCarousel.vue';
import GdprVideoplayer from '@/Feature/Media/code/src/components/GdprVideoplayer.vue';
import MainNavigationSlot from '@/Feature/Navigation/code/src/components/MainNavigationSlot.vue';
import HubspotForm from '@/Feature/Forms/code/src/components/HubspotForm.vue';
import HubspotFormWithDealers from '@/Feature/Forms/code/src/components/HubspotFormWithDealers.vue';
import Scrollytelling from '@/Feature/Scrollytelling/code/src/components/Scrollytelling.vue';
import HeaderVideo from '@/Feature/Header/code/src/components/HeaderVideo.vue';
import BaseVimeoPlayer from '@/Feature/Media/code/src/components/BaseVimeoPlayer.vue';
import Hotspot from '@/Feature/Hotspot/code/src/components/Hotspot.vue';
import ShowcaseGallery from '@/Feature/Teasers/code/src/components/ShowcaseGallery.vue';
import CertificateGenerator from '@/Feature/CertificateGenerator/code/src/components/CertificateGenerator.vue';
import TabbedContent from '@/Feature/TabbedContent/code/Sitecore.Feature.TabbedContent/src/components/TabbedContent.vue';

class VueApp {
    rootElementId = '#app';
    app = createApp();
    key = 'key';
    placeholderKey = 'placeholderkey';

    constructor() {
        this.init();
    }

    init() {
        this.addPlugins();
        this.addComponents();
        this.mount();
    }

    addPlugins() {
        this.app.use(store);
    }

    addComponents() {
        this.app
            .component('Catwalk', Catwalk)
            .component('MainNavigationSlot', MainNavigationSlot)
            .component('DaffcVocationals', DaffcVocationals)
            .component('MobileLanguageSelector', MobileLanguageSelector)
            .component('Countdown', Countdown)
            .component('ContentCarousel', ContentCarousel)
            .component('Doorway', Doorway)
            .component('GdprVideoplayer', GdprVideoplayer)
            .component('HeaderVideo', HeaderVideo)
            .component('HubspotForm', HubspotForm)
            .component('HubspotFormWithDealers', HubspotFormWithDealers)
            .component('Scrollytelling', Scrollytelling)
            .component('ShowcaseGallery', ShowcaseGallery)
            .component('TruckFilterOverview', TruckFilterOverview)
            .component('BaseVimeoPlayer', BaseVimeoPlayer)
            .component('Hotspot', Hotspot)
            .component('CertificateGenerator', CertificateGenerator)
            .component('TabbedContent', TabbedContent);
    }

    mount() {
        if (this.isSitecoreEditMode() || this.isExperienceEditor()) {
            this.mountWithSitecoreExperienceEditorSupport();
        } else {
            this.app.mount(this.rootElementId);
        }
    }

    mountWithSitecoreExperienceEditorSupport() {
        this.preventSitecoreKeyAttributesRemovalByVue();
        this.app.mount(this.rootElementId);
        this.restoreSitecoreKeyAttributes();
    }

    isSitecoreEditMode() {
        return window.scEditMode === 'edit';
    }

    isExperienceEditor() {
        if (typeof window.Sitecore === 'undefined') return false;
        return !!(window.Sitecore && window.Sitecore.PageModes && window.Sitecore.PageModes.PageEditor);
    }

    preventSitecoreKeyAttributesRemovalByVue() {
        this.copyKeysToPlaceholders();
        this.removeKeys();
    }

    restoreSitecoreKeyAttributes() {
        this.restoreKeysFromPlaceholders();
        this.removePlaceholders();
    }

    copyKeysToPlaceholders() {
        for (const element of this.keys()) {
            element.dataset[this.placeholderKey] = element.getAttribute(this.key);
        }
    }

    removeKeys() {
        for (const element of this.keys()) {
            element.removeAttribute(this.key);
        }
    }

    restoreKeysFromPlaceholders() {
        for (const element of this.placeholders()) {
            element.setAttribute(this.key, element.dataset[this.placeholderKey]);
        }
    }

    removePlaceholders() {
        for (const element of this.placeholders()) {
            element.removeAttribute(`data-${this.placeholderKey}`);
        }
    }

    keys() {
        return document.querySelectorAll(`code[${this.key}]`);
    }

    placeholders() {
        return document.querySelectorAll(`code[data-${this.placeholderKey}]`);
    }
}

export default VueApp;
