<template>
    <div class="showcase-gallery" data-dm="component.showcase-gallery">
        <div ref="showcaseGallery" class="showcase-gallery__slides swiper swiper-container">
            <div class="swiper-wrapper">
                <div v-for="(slide, index) in slides" :key="index" class="swiper-slide">
                    <img
                        ref="swiperImages"
                        class="showcase-gallery__image"
                        :alt="slide.image.alt"
                        :src="slide.image.mw992"
                        :srcset="`${slide.image.mw1920} 1920w, ${slide.image.mw992} 992w, ${slide.image.mw768} 768w, ${slide.image.mw576} 576w`"
                        sizes="(min-width: 993px) 1200px, (min-width: 769px) 992px, (min-width: 577px) 768px, 576px"
                        loading="lazy"
                    />
                </div>
            </div>
        </div>

        <div
            class="showcase-gallery__description"
            :class="{ 'is-visible': slideWidth !== 'auto' }"
            :style="{ width: slideWidth }"
        >
            <div class="showcase-gallery__description-text" :style="{ height: descriptionContentHeight }">
                <div
                    v-for="(slide, index) in slides"
                    :key="index"
                    ref="descriptions"
                    class="showcase-gallery__description-wrapper"
                    :class="{ 'is-active': index === activeIndex }"
                >
                    <h3 class="showcase-gallery__slide-title" v-html="slide.title"></h3>
                    <p class="showcase-gallery__slide-description" v-html="slide.description"></p>
                    <a v-if="slide.link" class="showcase-gallery__slide-link" :href="slide.link">
                        {{ slide.linkText }}
                        <span class="icon icon--chevron-right">
                            <svg role="img" title="icon: chevron right">
                                <use href="/assets/icons/common/ui.svg#chevron-right" />
                            </svg>
                        </span>
                    </a>
                </div>
            </div>
            <div ref="showcaseGalleryPagination" class="showcase-gallery__pagination"></div>
            <div class="showcase-gallery__navigation">
                <a ref="btnPrev" class="showcase-gallery__navigation-button btn" data-dm-element-value="previous slide">
                    <span class="icon icon--arrow-back">
                        <svg title="arrow back">
                            <use href="/assets/icons/common/ui.svg#arrow-back" />
                        </svg>
                    </span>
                </a>
                <a ref="btnNext" class="showcase-gallery__navigation-button btn" data-dm-element-value="next slide">
                    <span class="icon icon--arrow-forward">
                        <svg title="arrow forward">
                            <use href="/assets/icons/common/ui.svg#arrow-forward" />
                        </svg>
                    </span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper';
import { Keyboard, A11y, Navigation, Pagination } from 'swiper/modules';

export default {
    name: 'ShowcaseGallery',
    props: {
        slides: { type: Array, default: () => [] },
    },
    data() {
        return {
            swiper: null,
            activeIndex: -1,
            slideWidth: 'auto',
            descriptionContentHeight: 'auto',
        };
    },
    mounted() {
        this.initializeSwiperContainer();

        // wait until refs are initialized
        setTimeout(() => {
            this.setFixedContentSizes();
        }, 250);
    },
    beforeDestroy() {
        this.swiper.destroy();
    },
    methods: {
        initializeSwiperContainer() {
            this.swiper = new Swiper(this.$refs.showcaseGallery, {
                modules: [Keyboard, A11y, Navigation, Pagination],
                direction: 'horizontal',
                speed: 500,
                slidesPerView: 1.1,
                spaceBetween: 8,
                breakpoints: {
                    767: {
                        slidesPerView: 1.5,
                        spaceBetween: 16,
                    },
                    1440: {
                        slidesPerView: 1.8,
                        spaceBetween: 16,
                    },
                },
                on: {
                    init: (swiper) => {
                        const prevSlides = swiper.slides.filter((slide) => slide.classList.contains('swiper-slide-prev'));
                        for (const slide of prevSlides) {
                            slide.classList.add('swiper-slide-invisible-blank');
                        }
                    },
                    slideChange: (swiper) => {
                        if (!this.swiper || !swiper) {
                            this.activeIndex = -1;
                        } else {
                            this.activeIndex = swiper.realIndex;
                        }
                        if (this.activeIndex > 0) {
                            const prevSlides = swiper.slides.filter((slide) => slide.classList.contains('swiper-slide-invisible-blank'));
                            for (const slide of prevSlides) {
                                slide.classList.remove('swiper-slide-invisible-blank');
                            }
                        }
                    },
                    resize: () => {
                        this.setFixedContentSizes();
                    },
                },
                loop: true,
                centeredSlides: true,
                navigation: {
                    nextEl: this.$refs.btnNext,
                    prevEl: this.$refs.btnPrev,
                },
                pagination: {
                    el: this.$refs.showcaseGalleryPagination,
                    type: 'fraction',
                },
                resistance: false,
                keyboard: true,
                a11y: true,
            });
        },
        setFixedContentSizes() {
            this.setSlideContentWidth();
            // wait until slide content is calculated above
            this.$nextTick(() => {
                this.setDescriptionContentHeight();
            });
        },
        setSlideContentWidth() {
            if (this.swiper.slides) {
                const [firstSlide] = this.swiper.slides;
                this.slideWidth = `${firstSlide.offsetWidth}px`;
            }
        },
        setDescriptionContentHeight() {
            const descriptionHeights = this.$refs.descriptions.map(description => description.offsetHeight);
            const maxDescriptionHeight = Math.max(...descriptionHeights);
            this.descriptionContentHeight = `${maxDescriptionHeight}px`;
        },
    },
};
</script>
