<template>
    <div class="hotspot" :class="{ 'is-detail-open': isDetailViewOpen }" data-dm="component.hotspot">
        <transition name="hotspot-intro-fade" @after-leave="onMapFadeIn">
            <HotspotIntro v-if="!isMapOpen" :image="introImage" @start="openMap">
                <slot name="intro" :on-start="openMap" />
            </HotspotIntro>
        </transition>
        <transition name="hotspot-map-fade">
            <HotspotMap v-if="isMapOpen" :image="mapImage" :hotspots="hotspots" @select="openDetailView" />
        </transition>
        <transition name="fade">
            <HotspotDetail
                v-if="isDetailViewOpen"
                :title="selectedHotspot.title"
                :subtitle="selectedHotspot.subtitle"
                :description="selectedHotspot.description"
                :label="selectedHotspot.label"
                :image="selectedHotspot.image"
                @close="closeDetailView"
            />
        </transition>
    </div>
</template>

<script>
import HotspotIntro from './HotspotIntro';
import HotspotMap from './HotspotMap';
import HotspotDetail from './HotspotDetail';

export default {
    name: 'Hotspot',
    components: {
        HotspotIntro,
        HotspotMap,
        HotspotDetail
    },
    provide() {
        return {
            dictionary: this.dictionary
        };
    },
    props: {
        introImage: {
            type: Object,
            default: () => ({})
        },
        mapImage: {
            type: Object,
            default: () => ({})
        },
        hotspots: {
            type: Array,
            default: () => []
        },
        dictionary: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            isMapOpen: false,
            isDetailViewOpen: false,
            selectedHotspot: {}
        };
    },
    watch: {
        isDetailViewOpen(isOpen) {
            this.scrollHotspotIntoView();

            if (isOpen) {
                this.$nextTick(() => {
                    // elements rendered by Vue need to be reassessed by valtagmanager
                    if (window.digitalData) {
                        window.digitalData.update();
                    }
                });
            }
        }
    },
    methods: {
        openMap() {
            this.isMapOpen = true;
        },
        onMapFadeIn() {
            this.scrollHotspotIntoView();
        },
        openDetailView(hotspot) {
            this.selectedHotspot = hotspot;
            this.isDetailViewOpen = true;
        },
        closeDetailView() {
            this.isDetailViewOpen = false;
        },
        scrollHotspotIntoView() {
            this.$nextTick(() => {
                this.$el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                });
            });

            setTimeout(() => {
                // to make room for the hotspot and not have to deal with offsets
                this.unpinHeader();
            }, 400);
        },
        unpinHeader() {
            const header = document.querySelector('.header');
            if (header) {
                header.classList.add('headroom--unpinned');
            }
        },
    }
};
</script>