<template>
    <div class="container video-player__video embed-responsive embed-responsive-16by9" :data-type="type">
        <div :id="`videoPlayer${playerId}`" class="video-player__placeholder embed-responsive-item" />
    </div>
</template>

<script>
import YouTubePlayer from 'youtube-player';

export default {
    props: {
        type: { type: String, default: null },
        videoId: { type: String, default: null },
        playerId: { type: String, default: null },
    },
    data(){
        return{
        // Needed in the data so we can destroy it when the video ends.
            ytPlayer: null,
        };
    },
    methods: {
        onVideoPlay(){
            this.ytPlayer = YouTubePlayer(`videoPlayer${this.playerId}`);
            this.ytPlayer.loadVideoById(this.videoId);
            this.ytPlayer.playVideo();
            this.ytPlayer.on('stateChange', this.onPlayerStateChange);
        },
        onPlayerStateChange(event){
            if(event.data == 0){
                // We need to destroy it, otherwise the second time you press the play button, the video will start at the end (so it doesn't play at all).
                this.ytPlayer.destroy();
                this.$emit('stopVideo', false);
            }
        }
    }
};
</script>