import svg4everybody from 'svg4everybody';

import VueApp from './VueApp.js';

init();

function init() {
    new VueApp();
    upgradeAllRegisteredComponents();
    enableSvgSpritesheets();
}

function upgradeAllRegisteredComponents() {
    $(window).ready(() => {
        window.ComponentHandler.upgradeAllRegistered();
    });
}

function enableSvgSpritesheets() {
    svg4everybody();
}
