import AjaxHandler from 'AjaxHandler';

const ajax = new AjaxHandler();

/**
 * Wraps the default HubSpot response into a promise which resolves/rejects based on the status
 * @param {Promise} promise
 */
function handleHubspotResponse(promise) {
    return new Promise((resolve, reject) => {
        promise.then((response) => {
            if (response && response.status === 'error') {
                reject(response);
            } else {
                resolve(response);
            }
        });
    });
}

export const HubspotService = {
    /**
     * Creates a company property with the HubSpot API
     * @param {string} locale forms API locale
     * @param {object} options company property options
     * @returns {Promise} HubSpot response
     */
    createCompanyProperty(locale, options) {
        const { name, label } = options;
        if (!name || !label) {
            return Promise.reject(
                new Error(`name or label of company property is not defined. Found "${name}" and "${label}"`)
            );
        }

        return handleHubspotResponse(
            ajax.request({
                type: 'POST',
                url: `/${locale}/api/feature/forms/createcompanyproperty`,
                data: JSON.stringify(options),
            })
        );
    },
    /**
     * Obtains all HubSpot company properties from the dealer_information group in HubSpot
     * https://app.hubspot.com/property-settings/5631553/properties?group=dealer_information&type=0-2
     * @param {string} locale forms API locale
     * @returns {Promise} HubSpot response
     */
    getDealerCompanyPropertiesGroup(locale) {
        return handleHubspotResponse(
            ajax.request({
                type: 'POST',
                url: `/${locale}/api/feature/forms/getdealercompanyproperties`,
            })
        );
    },
};
