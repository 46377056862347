<script>
export default {
    name: 'CertificateGeneratorButton',
    props: {
        text: { type: String, required: true },
        disabled: { type: Boolean, required: true },
        loading: { type: Boolean, required: true },
        noMarginBottom: { type: Boolean, default: false }
    }
};
</script>

<template>
    <div class="search-block__button">
        <button
            :disabled="disabled"
            class="btn btn-primary cert-btn btn-sm mb-2"
            :class="{'cert-btn--loading': loading, 'mb-sm-0': noMarginBottom}"
            type="submit"
        >
            <span class="button__text">{{ text }}</span>
        </button>
    </div>
</template>