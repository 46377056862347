<template>
    <div class="hotspot-intro">
        <img
            class="hotspot-intro__image object-fit-cover"
            :alt="image.alt"
            :src="image.mw1920"
            :srcset="`${image.mw1920} 1920w, ${image.mw992} 992w,
                ${image.mw768} 768w, ${image.mw576} 576w`"
            sizes="(min-width: 993px) 1200px, (min-width: 769px) 992px, (min-width: 577px) 768px, 576px"
        />
        <div class="hotspot-intro__overlay"></div>
        <div class="container hotspot-intro__container">
            <div class="hotspot-intro__button-decoration-wrapper">
                <button
                    class="hotspot-button hotspot-button--large"
                    data-dm-element-value="discover now plus"
                    @click="handleStart"
                >
                    <span class="icon">
                        <svg role="img" title="Open map">
                            <use href="/assets/icons/common/ui.svg#plus"></use>
                        </svg>
                    </span>
                    <span class="hotspot-button__border"></span>
                </button>
            </div>
            <div class="hotspot-intro__details">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HotspotIntro',
    props: {
        image: {
            type: Object,
            default: () => ({}),
        }
    },
    methods: {
        handleStart() {
            this.$emit('start');
        },
    },
};
</script>
