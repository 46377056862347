<script>
export default {
    name: 'CertificateGeneratorIconDownload'
};
</script>

<template>
    <span class="icon download-icon mr-md-2">
        <svg role="img">
            <use href="/assets/icons/common/ui.svg#fa-download"></use>
        </svg>
    </span>
</template>