<script>
export default {
    name: 'CertificateGeneratorIconFeedback',
    props: {
        isValid: { type: Boolean, required: true}
    }
};
</script>

<template>
    <div class="feedback-icon" :class="{'valid-feedback': isValid, 'invalid-feedback': !isValid}">
        <span class="icon mr-2">
            <svg role="img">
                <use :href="`/assets/icons/common/ui.svg#${isValid ? 'fa-checkmark' : 'error'}`"></use>
            </svg>
        </span>
    </div>
</template>