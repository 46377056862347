<template>
    <div style="position: relative" :class="arrowColorClass">
        <div ref="contentCarousel" class="swiper swiper-container swiper-container--vue">
            <div class="swiper-wrapper">
                <div
                    v-for="i in itemLength"
                    :key="i"
                    class="swiper-slide"
                    :class="i === activeSlideIndex ? 'is-slickcurrent' : ''"
                >
                    <slot :name="`detail-${i}`" />
                </div>
            </div>
        </div>
        <button
            v-show="activeSlideIndex > 1 && itemLength > 2"
            ref="buttonPrev"
            type="button"
            class="content-carousel__btn content-carousel__btn--prev slick-arrow"
            aria-disabled="false"
        >
            <span class="icon icon--chevron-left">
                <svg role="img" title="icon: chevron left">
                    <use href="/assets/icons/common/ui.svg#chevron-left"></use>
                </svg>
            </span>
        </button>
        <button
            v-show="activeSlideIndex < itemLength && itemLength > 2"
            ref="buttonNext"
            type="button"
            class="content-carousel__btn content-carousel__btn--next slick-arrow"
            aria-disabled="false"
        >
            <span class="icon icon--chevron-right">
                <svg role="img" title="icon: chevron right">
                    <use href="/assets/icons/common/ui.svg#chevron-right"></use>
                </svg>
            </span>
        </button>
    </div>
</template>

<script>
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

export default {
    props:{
        itemLength: {
            type: Number,
            required: true,
        },
        arrowColorClass: {
            type: String,
            default: '',
        }
    },
    data(){
        return{
            swiper: null,
            activeSlideIndex: 2
        };
    },
    mounted() {
        this.swiper = new Swiper(this.$refs.contentCarousel, {
            modules: [Navigation, Pagination],
            navigation: {
                nextEl: this.$refs.buttonNext,
                prevEl: this.$refs.buttonPrev,
            },
            direction: 'horizontal',
            speed: 500,
            slidesPerView: 3,
            centeredSlides: true,
            initialSlide: 1,
            breakpoints: {
                // when window width is >= 320px
                320: {
                    slidesPerView: 1,
                },
                // when window width is >= 580px
                580: {
                    slidesPerView: 3,
                }
            },
            // These classes overwrite the default so i we do not get the default (unwanted) styling.
            slidePrevClass: 'prev-class',
            slideNextClass:'next-class',
            on: {
                slideChange: (swiper) => {
                    this.activeSlideIndex = swiper.activeIndex + 1;
                }
            }
        });
    },
    beforeDestroy() {
        this.swiper.destroy();
    },
};
</script>