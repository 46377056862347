<template>
    <div class="tabbed-content__navigation">
        <div class="navigation-tab container">
            <div
                v-for="(tab, index) in propsNavItems"
                :key="index"
                ref="navigationTab"
                class="navigation-tab__item"
                :data-tab="tab.Id"
                @click="setTabAnchor(tab)"
            >
                {{ tab.Name }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TabbedContent',

    props: {
        propsNavItems: {
            type: Array,
            default: () => [],
        },
    },

    mounted() {
        this.setActiveTab();

        onhashchange = (event) => {
            event.preventDefault();
            this.setActiveTab();
        };
    },

    methods: {
        setActiveTab() {
            const activeTab = this.propsNavItems.find((item) => {
                return this.getTabNameHash(item) === window.location.hash.replace('#', '');
            }) || this.propsNavItems[0];
            const tabs = document.querySelectorAll('.tab');
            const tabsNavItems = this.$refs.navigationTab;

            tabs.forEach((tab) => tab.classList.remove('active'));
            tabsNavItems.forEach((item) => item.classList.remove('active'));

            document.querySelector(`[data-tab-target="${activeTab.Id}"]`)?.classList.add('active');
            document.querySelector(`[data-tab="${activeTab.Id}"]`)?.classList.add('active');
        },
        setTabAnchor(tab) {
            window.location.hash = this.getTabNameHash(tab);
        },
        getTabNameHash(tab) {
            return encodeURIComponent(tab.Name.replace(/\s+/g, '-').toLowerCase());
        },
    },
};
</script>
