/**
 * Functionality for Maps implementations.
 *
 * @module project/Maps
 */

/**
 * Base implementation of Maps functionality.
 *
 * @memberof module:project/Maps
 * @version 1.0.0
 * @author Rocco Janse <rocco.janse@valtech.nl>
 */
class Maps {

    /**
     * Upgrades DOM element and initalizes vars.
     * @param {jQueryElement} $element DOM Element to be upgraded.
     * @param {object} options Configuration options.
     */
    constructor($element, options) {

        this.$element = $($element);

        // default config
        let config = {
            $mapContainer: this.$element.find('.map-container'),
            map: {
                center: { lat: -34.397, lng: 150.644 },
                zoom: 12,
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                events: {
                    onReady: () => {
                        this.onReady();
                    },
                    onResize: () => {
                        this.onResize();
                    }
                }
            }
        };

        // extend and overwrite config
        this.config = $.extend(true, config, options);

        this.$mapContainer = this.config.$mapContainer;

        // api loaded flag
        this.apiLoaded = false;
        this.initialized = false;

        // map and ui referral
        this.map = null;
        this.ui = null;

        // markers
        /* eslint-disable */
        this.markerSvgMarkup = [
            '<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">',
                '<g>',
                    '<path style="fill:#0F86BD;" d="M15,28.9c-1.6-3-8-9.4-9.9-13.5c-0.6-1.3-1-2.7-1-4.2C4.2,5.7,9.1,1.1,15,1.1s10.9,4.6,10.9,10.1c0,1.5-0.4,2.9-1,4.2l0,0C23,19.5,16.6,25.9,15,28.9L15,28.9z"/>',
                '</g>',
                '<g>',
                    '<path style="fill:none;stroke:#87C2DE;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;" d="M5.1,15.4c-0.6-1.3-1-2.7-1-4.2C4.2,5.7,9.1,1.1,15,1.1s10.9,4.6,10.9,10.1c0,1.5-0.4,2.9-1,4.2"/>',
                    '<line style="fill:none;stroke:#87C2DE;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;" x1="15.1" y1="28.9" x2="15.1" y2="28.9"/>',
                    '<path style="fill:none;stroke:#87C2DE;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;" d="M5.1,15.4c1.9,4.1,8.3,10.5,9.9,13.5"/>',
                    '<path style="fill:none;stroke:#87C2DE;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;" d="M24.9,15.4c-1.9,4.1-8.3,10.5-9.9,13.5h0v0"/>',
                '</g>',
                '<text x="15" y="18" font-size="10pt" font-family="Arial" font-weight="bold" text-anchor="middle" fill="#FFFFFF">{{LABEL}}</text>',
            '</svg>'
        ].join();

        this.myLocationSvgMarkup = [
            '<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">',
                '<path d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"/>',
            '</svg>'
        ].join();

        this.clusterSvgMarkup = [];
        /* eslint-enable */

        // set current
        this.last = {
            map: {
                center: this.config.map.center,
                zoom: this.config.map.zoom
            }
        };
        this.current = {
            map: {
                center: this.config.map.center,
                zoom: this.config.map.zoom
            }
        };

    }

    /**
     * Initialize.
     */
    init() {

        // determine and set dimensions
        const dimensions = this.getDimensions();
        this.setDimensions(dimensions.width, dimensions.height);

        this.loadApi().then(() => {

            // set api load flag
            this.apiLoaded = true;

            // init map
            this.initMap(this.$mapContainer[0], {
                dimensions: this.getDimensions(),
                center: this.current.map.center,
                zoom: this.current.map.zoom,
                zoomControl: this.config.map.zoomControl,
                mapTypeControl: this.config.map.mapTypeControl,
                scaleControl: this.config.map.scaleControl,
                //streetViewControl: this.config.map.streetViewControl,
                rotateControl: this.config.map.rotateControl,
                fullscreenControl: this.config.map.fullscreenControl
            }, this.config.map.events);
        });
    }

    /**
     * Sets center of the map.
     * @param {object|string} loc Coordinates object or location.
     * @param {float} loc.lat Latitude.
     * @param {float} loc.lng Longitude.
     */
    setCenter(loc) {
        this.map.setCenter(loc);
    }

    /**
     * Sets zoom level of the map.
     * @param {number} level Zoom level.
     */
    setZoom(level) {
        this.map.setZoom(level);
    }

    /**
     * Resizes the map viewport.
     */
    resize() {}

    /**
     * Resizes the map.
     */
    onResize() {
        const dimensions = this.getDimensions();
        this.setDimensions(dimensions.width, dimensions.height);
        this.resize();
        this.setCenter(this.current.map.center);
        this.setZoom(this.current.map.zoom);
    }

    /**
     * Triggered when map is loaded and ready.
     */
    onReady() {}

    /**
     * Returns current map container element.
     */
    getContainer() {
        return this.$mapContainer;
    }

    /**
     * Get current map viewport dimensions.
     * @returns {object} Object containing current width and height.
     */
    getDimensions() {
        return {
            width: this.$mapContainer.width(),
            height: this.$mapContainer.height()
        };
    }

    /**
     * Set map viewport dimensions.
     * @param {number} w New width.
     * @param {number} h New height.
     */
    setDimensions(w, h) {
        this.width = w;
        this.height = h;
    }

    /**
     * Returns element attributes where name starts with string.
     * @param {node} el DOM node to get attributes from.
     * @param {string} [str] Optional string of attribute start.
     * @returns {array} Array of attributes found.
     */
    getElementAttributes(node, str) {
        let attrs = {};
        for (const attribute of node.attributes) {
            if (str) {
                if (attribute.name.indexOf(str) === 0) {
                    attrs[attribute.name] = attribute.value;
                }
            } else {
                attrs[attribute.name] = attribute.value;
            }
        }
        return attrs;
    }
}

export default Maps;