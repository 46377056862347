<template>
    <div
        class="hotspot-detail"
        role="dialog"
        :aria-labelledby="title ? 'hotspot-detail-title' : null"
        :aria-describedby="description ? 'hotspot-detail-description' : null"
    >
        <div class="hotspot-detail__container">
            <div class="hotspot-detail__overlay"></div>
            <div class="hotspot-detail__content">
                <h3 v-if="subtitle" class="text-grey-300 mb-3">
                    {{ subtitle }}
                </h3>
                <h2 v-if="title" id="hotspot-detail-title" class="hotspot-detail__title h1 text-white">
                    {{ title }}
                </h2>
                <div
                    v-if="description"
                    id="hotspot-detail-description"
                    class="hotspot-detail__description text-grey-300"
                    v-html="description"
                ></div>
            </div>
        </div>
        <img
            ref="image"
            class="hotspot-detail__image object-fit-cover"
            :alt="image.alt"
            :src="image.mw992"
            :srcset="`${image.mw1920} 1920w, ${image.mw992} 992w,
                ${image.mw768} 768w, ${image.mw576} 576w`"
            sizes="(min-width: 993px) 1200px, (min-width: 769px) 992px, (min-width: 577px) 768px, 576px"
        />
        <div class="hotspot-detail__close">
            <span class="hotspot-detail__close-label">{{ dictionary.closeDetail }}</span>
            <button
                class="hotspot-button"
                :data-dm-element-value="`hotspot detail close ${label}`"
                @click="handleClose"
            >
                <span class="icon">
                    <svg role="img" title="Close overlay">
                        <use href="/assets/icons/common/ui.svg#close"></use>
                    </svg>
                </span>
            </button>
        </div>
    </div>
</template>

<script>
import objectFitImages from 'object-fit-images';

export default {
    name: 'HotspotDetail',
    inject: ['dictionary'],
    props: {
        title: {
            type: String,
            default: null,
        },
        subtitle: {
            type: String,
            default: null,
        },
        description: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        image: {
            type: Object,
            default: () => ({}),
        }
    },
    mounted() {
        this.refitImage();
    },
    methods: {
        handleClose() {
            this.$emit('close');
        },
        refitImage() {
            objectFitImages(this.$refs.image);
        },
    }
};
</script>