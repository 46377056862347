<script>
export default {
    name: 'CertificateGeneratorTabSelector',
    props: {
        singleTabText: { type: String, required: true },
        bulkTabText: { type: String, required: true },
    },
    emits: ['select-mode'],
    data() {
        return {
            isBulkMode: false
        };
    }
};
</script>

<template>
    <ul class="d-flex bulk-select-tabs">
        <li>
            <input
                id="single"
                v-model="isBulkMode"
                type="radio"
                name="bulk-select"
                :value="false"
                @change="$emit('select-mode', isBulkMode)"
            />
            <label for="single" class="py-2 px-4">{{ singleTabText }}</label>
        </li>
        <li>
            <input
                id="bulk"
                v-model="isBulkMode"
                type="radio"
                name="bulk-select"
                :value="true"
                @change="$emit('select-mode', isBulkMode)"
            />
            <label for="bulk" class="py-2 px-4">{{ bulkTabText }}</label>
        </li>
    </ul>
</template>