<template>
    <div :id="formContainerId" ref="formContainer" :class="{ 'hubspot-multi-step-form': multiStep }" />
</template>

<script>
import 'slick-carousel/slick/slick.js';

export default {
    props: {
        portalId: { type: String, required: true },
        formId: { type: String, required: true },
        redirectUrl: { type: String, default: undefined },
        multiStep: { type: Boolean, default: false },
    },
    computed: {
        formContainerId() {
            return `form-${this.formId}`;
        },
    },
    mounted() {
        const { formContainer } = this.$refs;

        this.injectScript({
            target: formContainer,
            src: '//js.hsforms.net/forms/v2.js',
            onLoad: () => {
                this.createForm();
            }
        });
    },
    methods: {
        createForm() {
            const { portalId, formId, redirectUrl } = this;

            window.hbspt.forms.create({
                portalId,
                formId,
                target: `#${this.formContainerId}`,
                onFormReady: ($form) => {
                    this.$emit('ready', $form);

                    if (this.multiStep) {
                        this.initializeMultiStepCarousel();
                    }
                },
                onFormSubmit: ($form) => {
                    this.$emit('submit', $form);
                },
                onFormSubmitted: () => {
                    this.$emit('submitted');
                },
                // URL to which the form will redirect upon a successful form completion
                redirectUrl
            });
        },
        injectScript({ target, src, onLoad }) {
            const script = document.createElement('script');
            script.src = src;
            script.onload = onLoad;
            target.appendChild(script);
        },
        /**
     * Code taken from: https://app.hubspot.com/design-manager/5631553/code/42797444118
     */
        initializeMultiStepCarousel() {
            var title = $('fieldset h2');
            var fieldSet = $('fieldset');
            title.parent().parent().addClass('section');

            $('fieldset + div.hs_submit').prev().addClass('last-slide');

            var wrapParentHTML = '<div class="step-form"><div class="step-form--container"></div></div>';
            var wrapSlidesHTML = '<div class="step-slide"><div class="step-slide--inner"></div></div>';

            fieldSet.each(function () {
                $(this).nextUntil('.section').addBack().wrapAll(wrapSlidesHTML);
            });

            $('.step-slide--inner .step-slide fieldset').unwrap().unwrap();
            $('.step-slide').wrapAll(wrapParentHTML);

            this.initSlick();

            $(this.$refs.formContainer).addClass('initialized');

            var $submitButton = $('<div id="submitSteps" class="slick-arrow submit">Submit</div>');
            $submitButton.on('click', () => {
                this.submitForm();
            });
            $('.step-form--container').append($submitButton);

            var slickNext = $('.slick-next');
            var slickSubmit = $('.slick-arrow.submit');
            var currentSlide = $('.step-form--container').slick('slickCurrentSlide');

            if (currentSlide === 0) {
                slickNext.addClass('centered');
                slickSubmit.addClass('centered');
            }

            $('.step-form--container').on('afterChange', function (event, slick, currentSlide) {
                if (currentSlide >= 1) {
                    slickNext.removeClass('centered');
                    slickSubmit.removeClass('centered');
                } else {
                    slickNext.addClass('centered');
                    slickSubmit.addClass('centered');
                }
            });

            $('.step-form').append('<h3 class="notification">Please complete all required fields.</h3>');
        },
        initSlick() {
            $('.step-form--container').slick({
                dots: true,
                infinite: false,
                speed: 300,
                slidesToShow: 1,
                adaptiveHeight: true,
                arrows: true,
                draggable: false,
                customPaging: function (slider, i) {
                    // var thumb = $(slider.$slides[i]).data();
                    return '<a>' + (i + 1) + '</a>';
                }
            });
        },
        submitForm() {
            let hasEmptyInputField = false;
            let hasEmptyCheckbox = false;

            $(this.$refs.formContainer).find('input[required], select[required]').each(function () {
                var $input = $(this);
                if (!$input.val()) {
                    $input.css('border', '1px solid red');
                    $input.focus(function () {
                        $input.css('border', '1px solid #cbd6e2');
                    });
                    hasEmptyInputField = true;
                } else {
                    $input.css('border', 'none');
                }
            });

            $(this.$refs.formContainer).find('ul[required]').each(function () {
                var $list = $(this);
                if ((!$list.find('input[type=checkbox]:checked').length > 0)) {
                    $list.css('border', '1px solid red');
                    hasEmptyCheckbox = true;
                } else {
                    $list.css('border', 'none');
                }
            });

            var hasFormValidationErrors = $('label.hs-error-msg').length > 0;

            if (hasFormValidationErrors || hasEmptyInputField || hasEmptyCheckbox) {
                $('.notification').fadeIn();
                $('.step-form--container').slick('slickGoTo', 1);
            } else {
                $(this.$refs.formContainer).find('form').submit();
                $('.notification').fadeOut();
            }
        },
    },
};
</script>
