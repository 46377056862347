import { store } from '@/Project/DAF/code/src/scripts/store.common';

export default {
    initializeCookieState() {
        return store.commit('initializeCookieState');
    },
    storeCookies(obj) {
        return store.commit('setCookieValues', obj);
    },
};
