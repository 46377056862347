<script>
import createPdfDownloadLinkFromBlob from '../util/downloadLink.js';
import CertificateGeneratorIconDownload from './CertificateGeneratorIconDownload.vue';

export default {
    name: 'CertificateGeneratorDownloads',
    components: { CertificateGeneratorIconDownload },
    props: {
        title: { type: String, required: true },
        vinNumberText: { type: String, required: true },
        licensePlateText: { type: String, required: true },
        downloadFileLinkText: { type: String, required: true },
        generatePdfApi: { type: String, required: true },
        vinNumbersList: {type: Array, required: true },
        loading: { type: Boolean, default: false },
    },
    methods: {
        async downloadPdf(vinNumber, refName) {
            const btn = this.clickedBtn(refName);
            this.disableBtn(btn);
            const lastPartOfVin = this.getLastEightChars(vinNumber);
            try {
                const response = await fetch(`${this.generatePdfApi}?vinRegistration=${lastPartOfVin}`);
                if (!response.ok) {
                    throw new Error('Something went wrong genarating the PDF', response.status);
                }
                const blob = await response.blob();
                createPdfDownloadLinkFromBlob(blob, lastPartOfVin);
            } catch (error) {
                console.error(error);
            } finally {
                this.enableBtn(btn);
            }
        },

        clickedBtn(refName) {
            if (!this.$refs[refName][0]) return;
            return this.$refs[refName][0];
        },

        disableBtn(btn) {
            if (btn) {
                btn.classList.value += ' cert-btn--loading';
                btn.disabled = true;
            }
        },

        enableBtn(btn) {
            btn.classList.value = btn.classList.value.replace('cert-btn--loading', '');
            btn.disabled = false;
        },

        getLastEightChars(str) {
            return str.slice(-8);
        },
    }
};
</script>

<template>
    <div class="downloads-section">
        <div class="downloads-section__title">{{ title }}</div>
        <div class="titles">
            <div class="pdf-headers">
                <div class="vin">{{ vinNumberText }}</div>
                <div class="plate">{{ licensePlateText }}</div>
            </div>
        </div>
        <hr />
        <div class="certificates">
            <div v-for="(cert, idx) in vinNumbersList" :key="idx">
                <div class="certificate">
                    <div class="pdf-meta-info">
                        <div v-if="cert.vinRegistration" class="certificate__vin-number vin">
                            {{ getLastEightChars(cert.vinRegistration) }}
                        </div>
                        <div v-if="cert.registrationNumber" class="certificate__licence-plate plate">
                            {{ cert.registrationNumber }}
                        </div>
                    </div>

                    <div class="certificate__download">
                        <button
                            :ref="'downloadPdfButton' + idx"
                            class="btn btn-outline-primary btn-sm cert-btn"
                            type="button"
                            @click="downloadPdf(cert.vinRegistration, 'downloadPdfButton' + idx)"
                        >
                            <CertificateGeneratorIconDownload />
                            <span class="button__text">{{ downloadFileLinkText }}</span>
                        </button>
                    </div>
                </div>
                <hr />
            </div>
        </div>
    </div>
</template>