function updateOneTrustAcceptedCookie(categoryKey, categoryValue) 
{
    let categorySimpleValue = categoryValue ? 1 : 0;

    window.OneTrust.UpdateConsent('Category', `${categoryKey}:${categorySimpleValue}`);
}

function isValidOneTrustKey(keyToCheck)
{
    return ['C0001', 'C0002', 'C0003', 'C0004', 'C0005'].includes(keyToCheck);
}

const OneTrustCookieHandler = {
    mapToOneTrustCookie(cookieObject) {
        let output = {};
        
        for(const [key, value] of Object.entries(cookieObject))
        {
            let oneTrustKey = key;

            if(!isValidOneTrustKey(oneTrustKey))
            {
                oneTrustKey = this.mapGdprCookieKeyToOneTrustCookieKey(key);
            }
    
            if(oneTrustKey)
            {
                output[oneTrustKey] = value;
            }
        }

        return output;
    },
    mapGdprCookieKeyToOneTrustCookieKey(gdprCookieKey) {
        switch (gdprCookieKey)
        {
            case 'functional': 
                return 'C0001';
    
            case 'analytics': 
                return 'C0002';
    
            case 'marketing': 
                return 'C0003';
    
            case 'functionalsocial': 
                return 'C0004';
    
            default: 
                return '';
        }
    },
    updateAcceptedCookies(updatedCookieObject) {
        
        for(const [key, value] of Object.entries(updatedCookieObject))
        {
            updateOneTrustAcceptedCookie(key, value);
        }
    },
    getActiveGroups() {
        return window.OnetrustActiveGroups;
    },
    getDefaultState() {
        return {
            'C0001': true,
            'C0002': false,
            'C0003': false,
            'C0004': false,
            'C0005': false
        };
    }
};

export default OneTrustCookieHandler;
